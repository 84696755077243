import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageRoomsService } from '../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';



@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedback:FormGroup;
  
  constructor(private roomService:ManageRoomsService, 
              private spinner:NgxSpinnerService,
              private formBuilder:FormBuilder) { }


  ngOnInit(): void {
    this.feedback = this.formBuilder.group({
      message: ['', [Validators.required]],
      review: ['', Validators.required],
      everVisited: ['', Validators.required],
      emailId: ['', Validators.required],
      mobileNo: ['', Validators.required]
    });
  }

  onSubmit(model:FormGroup){
    console.log(model.value)
    // this.spinner.show();
    this.roomService.postFeedback(model.value).subscribe(res => {
      console.log(res);

      this.alertSuccess();
      this.spinner.hide();

    })
  }

  alertSuccess() {
    swal.fire(
        'Success',
        'Feedback Added Successfully',
        'success'
    )
}

}


