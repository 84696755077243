
  <div class="backdrop" [ngStyle]="{'display':sigdisplay}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplay}">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Room</h4>
              <button type="button" class="close" (click)="hideAddModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form [formGroup]="createBooking" (ngSubmit)="onSubmitCreate(createBooking)">
          <div class="modal-body">
            <div class="form-group">
              <label >Floor No</label>
              <input formControlName="floorNo" class="form-control" type="number" required>
            </div>
              <div class="form-group">
                <label >Room No</label>
                <input formControlName="roomNo" class="form-control" type="number" required>
              </div>
              
              <div class="form-group">
                <label>Status</label>  
                <!-- <select formControlName="Status" class="form-control" type="text" required="required">
                  <option value="Available">Available</option>
                  <option value="Booked">Booked</option>
                  <option value="Locked">Locked</option>
                </select> -->

                <select formControlName="Status" class="form-control" type="text" required="required">
                  <option selected disabled value="">Select</option>
                  <option *ngFor="let item of statusList" [value]="item.status">{{item.status}}</option>
                </select>

              </div>
              
            
              <div class="form-group">
              <label>Room Type</label>  
              <!-- <select formControlName="roomType" class="form-control" type="text" required="required">
                <option value="single">single</option>
                <option value="double">double</option>
              </select> -->
               <select formControlName="roomType" class="form-control" type="text" required="required">
                  <option selected disabled value="">Select</option>
                  <option *ngFor="let item of roomTypeList" [value]="item.room_type">{{item.room_type}}</option>
                </select>
              </div>
              
              <div class="form-group">
                <label>Branch</label>  
                <select formControlName="Branch" class="form-control" type="text" required="required">
                  <option selected disabled value="">Select</option>
                  <option *ngFor="let item of branchList" [value]="item.branch_name">{{item.branch_name}}</option>
               
                </select>
              </div>
              
              <div class="form-group">
                <label>Amount</label>
                <input formControlName="Amount" class="form-control" type="text" required>
              </div>
              
              <div class="form-group">
                <label>Image Link</label>
                <input formControlName="imageLink2" class="form-control" type="text" required>
              </div>
            
              <!-- <button type="submit" class="btn btn-success">Book</button> -->
              
            
            </div>
            <div class="modal-footer">
           
              <button type="button" class="btn btn-secondary" (click)="hideAddModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Close</button>
              <button type="submit" class="btn btn-success"><span class="glyphicon glyphicon-send" aria-hidden="true"></span> Submit</button>
      
      
            </div>
          </form>
    
       
        
          </div>
      </div>
  </div> 
  
  <!--END START Modal -->



<div class="backdrop" [ngStyle]="{'display':sigdisplay2}"></div>
<!-- Button trigger modal -->
  <!-- Modal -->
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplay2}">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Update Room</h5>
            <button type="button" class="close" (click)="hideUpdateModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
      </div>
      
      <form [formGroup]="updateBooking" (ngSubmit)="onSubmitUpdate(updateBooking)">
        <div class="modal-body">
          
          
          <div class="form-group">
            <label >Floor No</label>
            <input formControlName="floorNo1" class="form-control" type="number" readonly required>
          </div>
            <div class="form-group">
              <label >Room No</label>
              <input formControlName="roomNo1" class="form-control" type="number" readonly required>
            </div>
            
            <div class="form-group">
              <label>Status</label>  
              <select formControlName="Status1" class="form-control" type="text" required="required">
                <option selected disabled value="">Select</option>
                  <option *ngFor="let item of statusList" [value]="item.status">{{item.status}}</option>
                </select>
            </div>
          
            <div class="form-group">
            <label>Room Type</label>  
            <select formControlName="roomType1" class="form-control" type="text" required="required">
              <option selected disabled value="">Select</option>
              <option *ngFor="let item of roomTypeList" [value]="item.room_type">{{item.room_type}}</option>
            </select>
            </div>
            
            <div class="form-group">
              <label>Branch</label>  
              <select formControlName="Branch1" class="form-control" type="text" required="required">
                <option selected disabled value="">Select</option>
                  <option *ngFor="let item of branchList" [value]="item.branch_name">{{item.branch_name}}</option>
               
                </select>
            </div>
            
            <div class="form-group">
              <label>Amount</label>
              <input formControlName="Amount1" class="form-control" type="text" required>
            </div>
  
            <div class="form-group">
              <label>Image Link</label>
              <input formControlName="imageLink1" class="form-control" type="text" required >
            </div>
            <div class="form-group">
              <label>Image Link</label>
              <input formControlName="imageLink2" class="form-control" type="text" required >
            </div>
            <div class="form-group">
              <label>Image Link</label>
              <input formControlName="imageLink3" class="form-control" type="text" required >
            </div>
            <div class="form-group">
              <label>Image Link</label>
              <input formControlName="imageLink4" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Image Link</label>
              <input formControlName="imageLink5" class="form-control" type="text" required >
            </div>
            <div class="form-group">
              <label>Air Conditioning (Yes/No)</label>
              <input formControlName="AirConditioning" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Room Service (Yes/No)</label>
              <input formControlName="room_service" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Free Wifi (Yes/No)</label>
              <input formControlName="wifi" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Parking Space for 2 Wheelers (Yes/No)</label>
              <input formControlName="parking2" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Parking Space for 4 Wheelers (Yes/No)</label>
              <input formControlName="parking4" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label> Pure drinking water (Yes/No)</label>
              <input formControlName="gym" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label> LED tv (32 inch ) (Yes/No)</label>
              <input formControlName="restaurant" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Adults (Number of adults)</label>
              <input formControlName="adults" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Check-In Time (add AM/PM after the time)</label>
              <input formControlName="checkin" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Check-Out Time (add AM/PM after the time)</label>
              <input formControlName="checkout" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Bed Type (Double beded/ Single beded)</label>
              <input formControlName="bed_type" class="form-control" type="text" required>
            </div>
            <div class="form-group">
              <label>Price (in Rupees)</label>
              <input formControlName="price" class="form-control" type="text" required>
            </div>

          </div>
          <div class="modal-footer">
         
            <button type="button" class="btn btn-secondary" (click)="hideUpdateModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Close</button>
            <button type="submit" class="btn btn-success"><span class="glyphicon glyphicon-send" aria-hidden="true"></span> Update</button>
    
    
          </div>
        </form>
      
        </div>
    </div>
</div> 
<!--END START Modal -->

<div class="backdrop" [ngStyle]="{'display':sigdisplay3}"></div>

<div class="modal" [ngStyle]="{'display':sigdisplay3}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Room</h5>
          <button type="button" class="close" (click)="hideDeleteModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="deleteRoom" (ngSubmit)="onSubmitDelete(deleteRoom)">
            <div class="modal-body">
          <input type="hidden" formControlName="roomNo2" class="form-control" required>
            <h5>Are you sure want to delete selected room ?</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="hideDeleteModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> No</button>
          <button type="submit" class="btn btn-info"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Yes</button>
        </div>
        </form>
      </div>
    </div>
  </div>




<!-- <div class="card-body">

  
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Room No</th>
        <th scope="col">Room Type</th>
        <th scope="col">Amount</th>
        <th scope="col">Branch</th>
        <th scope="col">Status</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody *ngFor="let item of roomsList">
      <tr>
        <td>{{item.room_no}}</td>
        <td>{{item.room_type}}</td>
        <td>{{item.amount}}</td>
        <td>{{item.branch}}</td>
        <td>{{item.status}}</td>
        <td>
          
              <button class="btn btn-primary" (click)="getDataPatch(item);">Update</button>
              <button class="btn btn-danger" (click)="deleteEventOnRow(item.room_no)">Delete</button>
           
        </td>
      </tr>
      
    </tbody>
  </table>
  </div> -->
  
  <ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

  <div class="container">
    <div class="card mt-3 shadow">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6">
                    <h3>Settings</h3>
                </div>
                <div class="col-md-6">
                    <div class="float-right">
                      <button type="button" class="btn btn-primary" (click)="showAddModal()">
                        <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Add Room
                      </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Floor No</th>
                <th scope="col">Room No</th>
                <th scope="col">Room Type</th>
                
                <th scope="col">Amount</th>
                <th scope="col">Branch</th>
                <th scope="col">Status</th>
                <th scope="col">Payment Status</th>
                <th scope="col">Payment ID</th>
               
                <th scope="col">Booking Id</th>
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody *ngFor="let item of roomsList">
              <tr>
                <td>{{item.floor_no}}</td>
                <td>{{item.room_no}}</td>
                <td>{{item.room_type}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.branch}}</td>

                <td class="text-success" *ngIf="item.status === 'Available' || item.status === 'available' || item.status === 'avilable'">{{item.status}}</td>
                <td class="text-danger" *ngIf="item.status === 'Booked' || item.status === 'booked' || item.status === null">{{item.status}}</td>
                <td class="text-secondary" *ngIf="item.status === 'Locked' || item.status === 'locked' ">{{item.status}}</td>

                <!-- <td>{{item.status}}</td> -->

                <td class="text-success" *ngIf="item.payment_status === 'Paid' || item.payment_status === 'paid'">{{item.payment_status}}</td>
                <td class="text-danger" *ngIf="item.payment_status === 'Pending' || item.payment_status === 'Notpaid' || item.payment_status === null">{{item.payment_status}}</td>
               
       
                <td>{{item.payment_id}}</td>

          
                <td>{{item.booking_id}}</td>
                <td>
                  <button class="btn btn-sm btn-primary " (click)="getDataPatch(item)"><span class="glyphicon glyphicon-edit" aria-hidden="true"></span></button>
                  &nbsp; &nbsp;  <button class="btn btn-sm btn-danger" (click)="deleteEventOnRow(item.room_no)"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                </td>
              </tr>
              
            </tbody>
          </table>
            
        </div>
    </div>
</div>
 