

  <ngx-spinner 
  bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
  [fullScreen] = "true"><p style="color: white" > Loading... </p>
  </ngx-spinner>

 <div class="container">
  <div class="row justify-content-md-center">

<div class="card col-md-8 col-lg-8 col-xs-12 mx-auto mt-2 mb-2">
    <div class="card-body shadow">
       
            <form [formGroup]="feedback" (ngSubmit)="onSubmit(feedback)">

                <!-- <div class="page-header">
                    <img src="../../assets/images/Hotels.jpg" class="rounded mx-auto d-block img-fluid" alt="..." width="300px"
                        height="
        100px">
                </div> -->

                <hr>
                <div class="row">
                    <div class="col md 6">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea6"><b>PAGE COMMENTS</b></label>
                            <textarea class="form-control rounded-0" formControlName="message" id="exampleFormControlTextarea2" rows="6"
                                placeholder="Please enter your comments about this page"
                                style="border: 1px solid black;"></textarea>
                        </div>
                    </div>
                </div>
                <p><b>OPTIONAL QUESTIONS</b></p>
                <p><b>How likely are you to return to Vguestin.com?</b></p>
                <div class="form-group" style="border: 1px solid black;">
                    <select class="browser-default custom-select" formControlName="review">
                        <option selected>Please choose one..</option>
                        <option value="Highly likely">Highly likely</option>
                        <option value="Somewhat Likely">Somewhat Likely</option>
                        <option value="Unsure">Unsure</option>
                        <option value="Somewhat unlikely">Somewhat unlikely</option>
                        <option value="Highly unlikely">Highly unlikely</option>
                    </select>
                </div>
                <hr>
                <p><b>Did you accomplish what you wanted to do on this page? (Please tell us why in the comment box
                        above)</b></p>
                        <div class="custom-control custom-radio custom-control-inline" style="float: right;">
                            <input type="radio" id="visitedYes" value="Yes" formControlName="everVisited" class="custom-control-input">
                            <label class="custom-control-label" for="visitedYes"><b>Yes</b></label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline" style="float: right;">
                            <input type="radio" id="visitedNo" value="No" formControlName="everVisited" class="custom-control-input">
                            <label class="custom-control-label" for="visitedNo"><b>No</b></label>
                          </div>
                <br>
                <br>
                <hr>
                <div class="form-group">
                    <label for="exampleInputEmail1"><b>Email address</b></label>
                    <input type="email" class="form-control" formControlName="emailId" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter email" style="border: 1px solid black;">
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1"><b>Phone Number</b></label>
                    <input type="email" class="form-control" formControlName="mobileNo" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Phone Number" style="border: 1px solid black;">
                </div>
                <hr>
                <div class="row">
                    <div class="col md 6">
                        <!-- <small><u>Privacy Policy</u></small>
                        <br>
                        <small>Powered by Vguestin</small> -->
                    </div>
                    <div class="col md 6">
                        <div class="form-group">
                            <button class="btn btn-secondary btn-lg float-right" type="submit">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
</div>

