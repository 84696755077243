<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button
            
        


         class="w3-button w3-teal w3-xlarge w3-hide-large" onclick="w3_open()"><span class="glyphicon glyphicon-align-justify"></span>
          </button>
         


        <span> V Guest Inn </span>

        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <!-- <li>
                    <button mat-icon-button>
                        <mat-icon>settings</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li> -->
                <li>
                    <button mat-button (click)="logout()">
                        <mat-icon style="font-size: 36px;">power_settings_new</mat-icon>
                    </button>
                    <!-- <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            Sign out
                        </button>
                    </mat-menu> -->

                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

