<!-- START PRELOADER -->
<!-- <div class="preloader">
			<div class="status">
				<div class="status-mes"></div>
			</div>
		</div> -->
<!-- END PRELOADER -->
<!-- START NAVBAR -->
<div class="navbar navbar-default navbar-fixed-top menu-top">
	<div class="container">
		<div class="navbar-header">
			<button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
				<!-- <span class="sr-only">Toggle navigation</span> -->
				<span class="fa fa-navicon" style="color:#fab903"></span>
				<!-- <span class="icon-bar"></span>
				<span class="icon-bar"></span> -->
			</button>
			<!--  <a href="index.html" class="navbar-brand"><img src="assets/img/logo.png" alt="logo"></a>-->
		</div>
		<div class="navbar-collapse collapse">
			<!-- <a class="navbar-brand" href="index.html" style="text-decoration: none;"><img src="../../assets/img/logo/260_80.PNG" /></a>  -->

			<nav>
                <!-- height: 68px;width: 220px; -->
                <a href="/#/" style="text-decoration: none;">
                    <img src="../../assets/img/logo/260_80.PNG" style="height: 68px;width: 220px;" />
                </a> 
				<ul class="nav navbar-nav navbar-right mt-3" >
					<li>
						<a class="page-scroll" href="index.html">Home</a>
					</li>

					<li>
						<a class="page-scroll" href="#feature">About</a>
					</li>
					<!-- <li>
						<a class="page-scroll" (click)="gotoRoomsPage()">Rooms</a>
					</li> -->
					<li>
						<a class="page-scroll" href="#check">Check Availability</a>
					</li>
					<li>
						<a class="page-scroll"(click)="gotoSearchingPage()" >My Booking
						</a>
					</li>
					<li>
						<a class="page-scroll" href="#contact">Contact Us</a>
					</li>
					<li>
						<a class="page-scroll" (click)="gotoLoginPage()">Login as Admin
						</a>
					</li>
					
					<li>
						<a class="page-scroll" (click)="gotoFeedbackPage()">Feedback
						</a>
					</li>
					
					<!-- <li>
						<a class="page-scroll" (click)="gotoContactpage()">Contact</a>
					</li>  -->

					<!-- <li>
						<a class="page-scroll" href="#ourservice">Our Services</a>
					</li>
					<li>
						<a class="page-scroll" href="#portfolio-web">Portfolio</a>
					</li> -->
					<!-- <li>
						<a class="page-scroll" (click)="gotoContactpage()">Contact</a>
					</li> -->
					<!-- <li>
						<a class="page-scroll">Careers</a>
					</li> -->
				</ul>
			</nav>
		</div>
	</div>
	<!--- END CONTAINER -->
</div>
<!-- END NAVBAR -->


<!-- START HOME -->
<section id="home" class="home_bg" *ngIf="hideMediumDown" style="background : #595242; background-size: cover; background-position: 50% 50%">
	<div class="container" style="margin-top: 15px;">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-xs-12 psClass" id="particle-slider">


				
     
					<div id="myCarousel" class="carousel slide" data-ride="carousel">
					  <!-- Indicators -->
					  <ol class="carousel-indicators">
						<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
						<li data-target="#myCarousel" data-slide-to="1"></li>
						<li data-target="#myCarousel" data-slide-to="2"></li>
						<li data-target="#myCarousel" data-slide-to="3"></li>
					  </ol>
				  
					  <!-- Wrapper for slides -->
					  <div class="carousel-inner">
						
						<div class="item active">
							<!-- <img src="https://triplanco.com/wp-content/uploads/2019/01/hotel_triplanco.jpg" alt="html" style="width:150%;">					   -->
							<!-- <img src="assets/img/acc2.jpeg" alt="html" style="width:150%;height: 525px;">					   -->
							<img src="assets/imgone/acc1.jpg" alt="html" style="width:150%;height: 640px;">					  
						
						</div>
						<div class="item ">
							<img src="assets/imgone/acc4.jpg" alt="html" style="width:150%;height: 640px;">		
						</div>
						
							<div class="item">
							  <!-- <img src="https://res.cloudinary.com/simplotel/image/upload/x_0,y_180,w_4288,h_2411,r_0,c_crop,q_60,fl_progressive/w_1500,c_fit,f_auto/trimrooms-jaipur---mount-blue/DSC_7909_copy_r1qr7q" alt="html" style="width:200%;"> -->
							  <img src="assets/imgone/acc2.jpg" alt="html" style="width:150%;height: 640px;">	
							
							</div>
							<div class="item ">
								<img src="assets/imgone/acc3.jpg" alt="html" style="width:150%;height: 640px;">		
							</div>

						
						  
					  </div>
				  
					  <!-- Left and right controls -->
					 <!--<a class="left carousel-control" href="#myCarousel" data-slide="prev">
						<span class="glyphicon glyphicon-chevron-left"></span>
						<span class="sr-only">Previous</span>
					  </a>
					  <a class="right carousel-control" href="#myCarousel" data-slide="next">
						<span class="glyphicon glyphicon-chevron-right"></span>
						<span class="sr-only">Next</span>
					  </a>
					</div>
				  </div>
				-->
			</div>
				 <!-- <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']" >  
					<div class="item" *ngFor="let img of Images">  
					<div style="align-content: center">  
					<img style="height: 530px;width:100%"src={{img}}/>  
					</div>  
					</div>  
					</owl-carousel>   -->
					
					<!-- <carousel>
						<slide>
						  <img src="../assets/img/download.jpg" alt="first slide" style="display: block; width: 100%;">
						</slide>
						<slide>
						  <img src="../assets/img/images.jpg" alt="second slide" style="display: block; width: 100%;">
						</slide>
						<slide>
						  <img src="../assets/img/images_1.jpg" alt="third slide" style="display: block; width: 100%;">
						</slide>
					  </carousel> -->
				
		</div>
			<!--- END COL -->
		</div>
		<!--- END ROW -->
		
			  
	</div>
	<!--- END CONTAINER -->


</section>
<!-- END  HOME -->



	  

<section id="feature" class="about-content" style="background-color: #e3f2fd">
	<div class="container">
		<div class="row ">
			<div class="section-title text-center wow zoomIn">
				<h2>About Us</h2>
				<div class="line"></div>
				<p class="about-title">
					"We are a chain of guest houses by various channels of booking facilities like Web, Mobile app and through phone calls. Customers can manage their stay very much themselves by doing self-check in. Only the watchman will be at the property to safeguard and clean the property. No food will be served except water, customers require to manage the food of their own."
				</p>
				<br/>
				<div class="about-title" style="font-size: large;">
					"Customers have to check in using the V Guest Inn app using the three step process."	
					<li>Taking Selfie</li>
					<li>Upload ID document</li>
					<li>Enter valid mobile number</li>
				</div>
				<br/>
				
				
			</div>

		</div>
		<!-- END ROW-->
	</div>
	<!-- END CONTAINER-->
</section>
<!-- END ABOUT -->


<section id="check">
	<div class="container">
		<div class="section-title text-center wow zoomIn">
			<h2>Check Availability</h2>
			<div class="line"></div>

			<img src="assets/img/check-in.png" class="img-responsive mx-auto" style="height: 120px;width: 120px;">
		</div>
		<div class="row">
		 
		  <!-- planner-->
		  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 planner">
			<div class="planner-block">
			  <form class="form-planner form-horizontal"
			   [formGroup]="checkAvailability"
          (ngSubmit)="onSubmitCheckAvailability(checkAvailability)">
				<div class="row">
					<div class="col-lg-1 col-md-1 col-sm-12"></div>
				  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="form-group">
					  <label style="color: #ffb900; font-size: 14px;">Check in</label>
					  
					  <input type="text"
                      placeholder="Check-In Date"
                      class="form-control"
                      formControlName="checkIn"
                      bsDatepicker [minDate]="minDate"
                      [bsConfig]="{ adaptivePosition: true }" required>   
					</div>
				  </div>
				
				  <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-group">
						<label>Room type</label>
						<div class="theme-select">
						 
						  <select formControlName="room_type" class="form-control" type="text" required="required" >
							<option [ngValue]="0" disabled>Select</option>
							  <option *ngFor="let item of roomType" value="{{item.room_type}}">{{item.room_type}}</option>
						   
							</select>
						</div>
					</div>
				  </div> -->


				  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="form-group">
						<label>Branch</label>
						<div class="theme-select">
						<select formControlName="branch" class="form-control" type="text" required="required">
							<option [ngValue]="0" disabled>Select</option>
							  <option *ngFor="let item of branchList" value="{{item.branch_name}}">{{item.branch_name}}</option>
						   
							</select>
						</div>
					  </div>
					</div>


					<div class="col-lg-3 col-md-3 col-sm-12">
						<div class="planner-check-availability">
						  <button type="submit" class="btn btn-lg btn-primary" style="margin-left: 24px;">Submit</button>
	
						</div>

				</div>
				<div class="col-lg-1 col-md-1 col-sm-12"></div>
				</div>
				<!-- <div class="row">
				  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="form-group">
						<label>Room type</label>
						<div class="theme-select">
						 
						  <select formControlName="room_type" class="form-control" type="text" required="required" >
							<option [ngValue]="null">Select</option>
							  <option *ngFor="let item of roomType" value="{{item.room_type}}">{{item.room_type}}</option>
						   
							</select>
						</div>
					</div>
				  </div>
				</div> -->
				<!-- <div class="row">
				 
				 
				  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div class="form-group">
						<label>Branch</label>
						
						<select formControlName="branch" class="form-control" type="text" required="required">
							<option [ngValue]="null">Select</option>
							  <option *ngFor="let item of branchList" value="{{item.branch_name}}">{{item.branch_name}}</option>
						   
							</select>
					  </div>
					</div>
				</div> -->
				
				<!-- <div class="row">
				  <div class="col-lg-12 col-md-12 col-sm-12">
					<div class="planner-check-availability">
					  <button type="submit" class="btn btn-sm btn-primary" [disabled]="!checkAvailability.valid">Submit</button>

					</div>
				  </div>
				</div> -->
			  </form>
			</div>
		  </div>
		  <!-- /planner-->
		 
		  </div>
	</div>
	
</section>
<!-- End OUR Image  -->
<!-- START OUR VIDEO  -->
<!-- <div class="own-video text-center"
	style="background-image: url(assets/img/bg/video-bg.jpg); background-size: cover; background-position: center center; background-attachment: fixed;">
	<h3>Introducing Rasi Infotech App</h3>
	<a class="video-play"
		href="https://www.youtube.com/watch?v=v5eAbbkaAfU"><img
		src="assets/img/play-button.png" alt="" /></a>
	<p>Watch video. It's takes 30sec only.</p>
</div> -->
<!-- END OUR VIDEO  -->
<!-- <section id="portfolio-web">
	<div class="container">
		<div class="portfolio-heading margin_top40 mytitle">
			<h1 class="text-center">More Portfolio</h1>
			<h3 class="text-center">Our expertise &amp; creativity across different websites.</h3>
		</div>
		<div class="web-sec margin_top40">
			<ul>
				<li class="mySlides">
					<a href="avu.html">
						<div class="web-hover-box">
							<img src="assets/img/portfolio/dantcar.png" alt="" style="max-width: 300px;">
							<div class="content-box">
								<h2>View Dant-Care Details</h2>
								<h2></h2>
							</div>
						</div>
					</a>
				</li>
				<li class="mySlides">
					<a href="avu.html">
						<div class="web-hover-box">
							<img src="assets/img/portfolio/kaveri.png" alt="" style="max-width: 300px;">
							<div class="content-box">
								<h2>View Kaveri Details</h2>
								<h2> </h2>
							</div>
						</div>
					</a>
				</li>
				<li class="mySlides">
					<a href="exhbit.html">
						<div class="web-hover-box">
							<img src="assets/img/portfolio/sanpets1.png" alt="" style="max-width: 300px;">
							<div class="content-box">
								<h2>View Sanpets Details</h2>
								<h2> </h2>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a href="avu.html">
						<div class="web-hover-box">
							<img src="assets/img/portfolio/dealerpalpng.png" alt="" style="max-width: 300px;">
							<div class="content-box">
								<h2>View Dealerpal Details</h2>
								<h2> </h2>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</section> -->
<!-- START OUR Image  -->
<!-- <section id="portfolio-web">
	<div class="container">

		<div class="col-md-12 col-sm-12 col-xs-12">

			<div class="portfolio-heading margin_top40 mytitle">
				<h1 class="text-center">More Portfolio</h1>
				<h3 class="text-center">Our expertise &amp; creativity across different websites.</h3>
			</div>
			<div>
				<div class="row">
					<div class="col-md-3 col-sm-3 col-xs-12"></div>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<ngx-carousel [inputs]="carouselOne">
							<button NgxCarouselPrev class='leftRs'>&lt;</button>
							<ngx-item NgxCarouselItem>
								<a href="https://dealerpal.in/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/dealerpalpng.png" alt="" class="img-responsive img1">
										<div class="content-box">
											<h2>View Dealerpal Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>

								<a href="http://dev-rasiinfotech.com:82/#/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/kaveri.png" alt="" class="img-responsive img1">
										<div class="content-box">
											<h2>View Kaveri Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>

							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a href="http://drkohilasdentalcentre.com/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/dantcar.png" alt="" class="img-responsive img3">
										<div class="content-box">
											<h2>View Dr.Kohila's Dental Centre </h2>
											<h2></h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a href="http://dev-rasiinfotech.com:94/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/hotel.png" alt="" class="img-responsive img2">
										<div class="content-box">
											<h2>View Salem Hotel Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a [routerLink]="['/services']">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/sanpets1.png" alt="" class="img-responsive img3">
										<div class="content-box">
											<h2>View Sanpets Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a href="https://valuecabsindia.com/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/cab.png" alt="" class="img-responsive img2">
										<div class="content-box">
											<h2>View ValuecabsIndia Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a href="http://racesonline.in/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/racesonline_in.jpg" alt="" class="img-responsive img1">
										<div class="content-box">
											<h2>View Races Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>

							<ngx-item NgxCarouselItem>
								<a href="http://dev-rasiinfotech.com:96/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/gym.png" alt="" class="img-responsive img2">
										<div class="content-box">
											<h2>View GYM cart Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<ngx-item NgxCarouselItem>
								<a href="http://dev-rasiinfotech.com:100/" target="_blank">
									<div class="web-hover-box">
										<img src="assets/img/portfolio/aqua.png" alt="" class="img-responsive img2">
										<div class="content-box">
											<h2>View Aqua clinic Details</h2>
											<h2> </h2>
										</div>
									</div>
								</a>
							</ngx-item>
							<button NgxCarouselNext class='rightRs'>&gt;</button>
						</ngx-carousel>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12"></div>
				</div>
			</div>
		</div>
	</div>
</section> -->
<section id="contact">
<div class="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-4 col-xs-4">
				<table cellspacing="20px">
					

					<!-- <tr> <td class="style-title">Address:</td> </tr> -->
					<tr> <td class="style-title">Tenali Guest House</td> </tr>
					<tr> <td class="style-text">157,Salipeta,Tenali,</td> </tr>
					<tr> <td class="style-text"> Guntur District,Andhra Pradesh- 522201</td> </tr>
                    <br>
                    <tr> <td class="style-title">Contact Number</td> </tr>
                    <tr> <td class="style-text"> Hotel Manager (Bhaskar) : +91 9182342105 </td> </tr>
					<!-- <tr> <td class="style-title">Hyderabad</td> </tr>
					<tr> <td class="style-text">7-1-211/28,Opp.Indian Bank,D.K Road,Ameerpet,Hyderabad Telangana- 500038</td> </tr>
					 -->
				


				</table>

			</div>
			<div class="col-md-4 col-sm-4 col-xs-4 text-center wow zoomIn">
				<div class="footer_logo">
					<a href="/#/" style="text-decoration: none;">
						<img src="../../assets/img/logo/260_80.PNG" />
					</a> 
					<!-- <a href="index.html">
						<p class="footer_txt resFooter">V Guest Inn</p>
					</a> -->
					<!-- <p class="text-white">
						Consumer Helpline Number: +91 - 22 - 6140 4300 
					</p> -->
					<p class="text-white" style="font-size: medium;">
						Days : Monday to Friday
					</p>
				</div>
				<div class="footer_social">
					<ul>
						<li>
							<a target="_Blank" class="f_facebook" href="">
								<i class="fa fa-facebook"></i>
							</a>
						</li>
						<li>
							<a target="_Blank" class="f_whatsapp" href="https://web.whatsapp.com/send?phone=+919949633109">
								<!-- <a class="f_whatsapp" href="intent://send/7639900033#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end"> -->
								<i class="fa fa-whatsapp"></i>
							</a>
						</li>
						<li>
							<a class="f_skype" href="skype:live:narendra.vinodala@gmail.com?call">
								<i class="fa fa-skype"></i>
							</a>
						</li>
					</ul>
				</div>



				




				<div class="copyright" style="font-size: medium;">
					<p>Copyright &copy; 2020 | Accuracy.</p>
				</div>
				<!--- END FOOTER COPYRIGHT -->
			</div>
			<div class="col-md-4 col-sm-4 col-xs-4">
				<table cellspacing="20px">
					

					<!-- <tr> <td class="style-title">Address:</td> </tr> -->
					<!-- <tr> <td class="style-title">Tenali</td> </tr>
					<tr> <td class="style-text">157,Salipeta,Tenali,</td> </tr>
					<tr> <td class="style-text"> Guntur District,Andhra Pradesh</td> </tr></td> </tr> -->
					<tr> <td class="style-title">Hyderabad Guest House</td> </tr>
					<tr> <td class="style-text">7-1-211/28,Opp.Indian Bank,D.K Road,</td> </tr>
					<tr> <td class="style-text"> Ameerpet,Hyderabad Telangana- 500038</td> </tr> 
                    <br>
                    <tr> <td class="style-title">Contact Number</td> </tr>
                    <tr> <td class="style-text"> Hotel Manager (Sendil) : +91 7702526310 </td> </tr>
					
				


				</table>
			</div>
			<!--- END COL -->
		</div>
		<!--- END ROW -->
	</div>
	<!--- END CONTAINER -->
</div>
<!-- END FOOTER -->
</section>




<div class="footer_logo">
	<a class="page-scroll" (click)="gotoChatbotPage()" target="_blank">Chatbot</a>
		

	<!-- <p class="text-white">
		Consumer Helpline Number: +91 - 22 - 6140 4300 
	</p> -->
	<p class="text-white" style="font-size: medium;">
		Days : Monday to Friday
	</p>
</div>












