<!--<app-header></app-header>

    <mat-drawer-container>
        <mat-drawer mode="side" [opened]="sideBarOpen">
            <app-sidebar></app-sidebar>
        </mat-drawer>
        <mat-drawer-content><router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>

<app-footer></app-footer>-->
<head>
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
  
</head>
<app-header></app-header>
<body>
    <div class="w3-sidebar w3-bar-block w3-collapse w3-card w3-animate-left" style="width:280px; padding: 5px;" id="mySidebar">
        <button class="w3-bar-item w3-button w3-large w3-hide-large" onclick="w3_close()">Close &times;</button>
        
          <!-- <div class="profile-card">
              <div class="header">
                <h2>{{Name}}</h2>
                  <h4>{{Email}}</h4>
              </div>
              
          </div> -->
      
          <!-- <mat-divider></mat-divider> -->
      
           
      <li>
          
      <a  routerLink="/default/dashboard" onclick="w3_close()" >
              
      
              &nbsp;
      <h3><span class="glyphicon glyphicon-dashboard" aria-hidden="true"></span>  &nbsp; &nbsp;   <b> Dashboard  </b>  </h3></a></li>
    
    <li>  <a routerLink="/default/profile" onclick="w3_close()" >&nbsp;<h3><span class="glyphicon glyphicon-user" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Profile</b></h3>  </a></li>
         <li><a  routerLink="/default/change-password" onclick="w3_close()" >&nbsp; <h3><span class="glyphicon glyphicon-edit" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Manage Users</b> </h3> </a> </li>
      
         <li> <a   routerLink="/default/manage-rooms" onclick="w3_close()" >&nbsp;<h3><span class="glyphicon glyphicon-equalizer" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Settings</b></h3></a> </li>
          <!-- <li><a  routerLink="/default/booked" onclick="w3_close()" >&nbsp;<h3><span class="glyphicon glyphicon-lock" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Manage Rooms</b></h3></a></li> -->
          <li><a  routerLink="/default/reports" onclick="w3_close()" >&nbsp;<h3><span class="glyphicon glyphicon-save-file" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Daily Reports</b></h3></a></li>
          <li><a  routerLink="/default/admin-feedback" onclick="w3_close()" >&nbsp;<h3><span class="glyphicon glyphicon-list-alt" aria-hidden="true"></span>  &nbsp; &nbsp;<b>Feedback</b></h3></a></li>

      
    </div>
    
    <div class="w3-main" style="margin-left:300px">
       
        <router-outlet></router-outlet>
        
    <div class="w3-container">
        
    </div>
    
    </div>
    

    </body>
