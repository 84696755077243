<ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true"><p style="color: white" > Loading... </p>
</ngx-spinner>
<div class="container">
	<div class="card mt-3 shadow">
		<div class="card-header">
			<h4>Update Profile</h4>
		</div>
		<form [formGroup]="updateProfile" (ngSubmit)="onSubmitUpdate(updateProfile)">
		<div class="card-body">
				<div class="row">
					<div class="col-md-4">
						<div class="form-group">
							<label for="Name">Name:</label>
							<input type="text" formControlName="name" class="form-control" id="Name" required/>
							<div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
								<p *ngIf="f.name.errors.required" class="text-danger">
									Name required
								</p>
								<p *ngIf="f.name.errors.pattern" class="text-danger">
									Only alphabets
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label for="Area">Area:</label>
							<input type="text" formControlName="area" class="form-control"  id="Area" required/>
							<div *ngIf="f.area.invalid && (f.area.dirty || f.area.touched)">
								<p *ngIf="f.area.errors.required" class="text-danger">
									Area required
								</p>
								<p *ngIf="f.area.errors.pattern" class="text-danger">
									Only alphabets
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label for="City">City:</label>
							<input type="text" formControlName="city" class="form-control"  id="City" required/>
							<div *ngIf="f.city.invalid && (f.city.dirty || f.city.touched)">
								<p *ngIf="f.city.errors.required" class="text-danger">
									City required
								</p>
								<p *ngIf="f.city.errors.pattern" class="text-danger">
									Only alphabets
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					
					<div class="col-md-4">
						<div class="form-group">
							<label for="State">State:</label>
							<input type="text" formControlName="state" class="form-control"  id="State" required/>
							<div *ngIf="f.state.invalid && (f.state.dirty || f.state.touched)">
								<p *ngIf="f.state.errors.required" class="text-danger">
									State required
								</p>
								<p *ngIf="f.state.errors.pattern" class="text-danger">
									Only alphabets
								</p>
							</div>
						</div>
					</div>
					<!-- <div class="col-md-4">
						<div class="form-group">
							<label for="Country">Country:</label>
							<input type="text" formControlName="country" class="form-control"  id="Country">
						</div>
					</div> -->
					<div class="col-md-4">
						<div class="form-group">
							<label for="Mobile">Mobile No:</label>
							<input type="text" formControlName="mobile_no" class="form-control" (keypress)="keyPress($event)" 
							maxlength="10" minlength="10"  id="Mobile" required/>
							<div *ngIf="f.mobile_no.invalid && (f.mobile_no.dirty || f.mobile_no.touched)">
								<p *ngIf="f.mobile_no.errors.required" class="text-danger">
									Mobile Number required
								</p>
							</div>
						</div>
					</div>
				</div>
					
			</div>
			<div class="card-footer text-center">
				<button type="submit" class="btn btn-success">submit</button>
			</div>	
		</form>
	</div>
</div>