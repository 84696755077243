<div class="navbar navbar-default navbar-fixed-top menu-top" data-ng-init="apiRoomView()">
    <div class="container">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navBarToggle">
                <span class="fa fa-navicon naviClr"></span>
            </button>
        </div>
        <div class="navbar-collapse collapse" id="navBarToggle">
            <nav>
                <ul class="nav navbar-nav navbar-right mt-3">
                    <div class="row">
                        <li>
                            <a class="page-scroll navItem" href="index.html">Home</a>
                        </li>
                        &nbsp;&nbsp;&nbsp; &nbsp;
                    </div>
                </ul>
            </nav>
        </div>
    </div>
</div>

<br><br><br><br><br>
<div class="container">
    <div class="card">
        <div class="card-body">
            
            <div class="row" *ngFor="let roomData of data;">
                <div class="col-sm-6 pt-5">

                    
                              <!-- <div class="card mb-0"> -->
                                <div class="card-header">
                                  <h2 class="text-success"><i class="fas fa-house-user"></i>&nbsp; Room No : &nbsp; {{roomData.room_no}}  </h2>
                              
                                <!-- </div> -->
                              </div>
    
            
                    
                    <section>
                        <div id="myCarousel" class="carousel slide" data-ride="carousel">
                            <!-- Indicators -->
                            <ol class="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                                <li data-target="#myCarousel" data-slide-to="1"></li>
                                <li data-target="#myCarousel" data-slide-to="2"></li>
                                <li data-target="#myCarousel" data-slide-to="3"></li>
                                <li data-target="#myCarousel" data-slide-to="4"></li>
                            </ol>

                            <!-- Wrapper for slides -->
                            <div class="carousel-inner">
                                <div class="item active">
                                    <img src="{{roomData.image_link}}" alt="Los Angeles">
                                </div>

                                <div class="item">
                                    <img src="{{roomData.image_link1}}" alt="Chicago">
                                </div>

                                <div class="item">
                                    <img src="{{roomData.image_link2}}" alt="New York">
                                </div>

                                <div class="item">
                                    <img src="{{roomData.image_link3}}" alt="New York">
                                </div>

                                <div class="item">
                                    <img src="{{roomData.image_link4}}" alt="New York">
                                </div>
                            </div>
                         
                            <!-- Left and right controls -->
                            <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                                <span class="glyphicon glyphicon-chevron-left"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="right carousel-control" href="#myCarousel" data-slide="next">
                                <span class="glyphicon glyphicon-chevron-right"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </section>
                </div>
                <div class="col-sm-6 pt-5">
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
                        <!-- <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
                          style="border:0; padding-bottom: 15px;" allowfullscreen></iframe> -->
                          <div class="card">
                            <div class="card-header">
                              <h2>Amenities & Services </h2>
                            </div>
                            <div class="card-body">

                                <!-- //AIT CONDITIONAR -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.air_conditioning == 'Yes' || roomData.air_conditioning == 'yes'"> 
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                     Air Conditioning
                                  
                                </div>

                                <div class="card-body" style="font-size: large;" *ngIf="roomData.air_conditioning == 'No' || roomData.air_conditioning == 'no'"> 
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                     Air Conditioning
                                  
                                </div>
                                 <!-- //AIT CONDITIONAR -->

                                 <!-- ROOM SERVICE -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.room_service == 'No' || roomData.room_service == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                    Room Service
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.room_service == 'Yes' || roomData.room_service == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                    Room Service
                                  
                                </div>
                                    <!-- ROOM SERVICE -->

                                    <!-- Wi-Fi -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.free_wifi == 'Yes' || roomData.free_wifi == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                    Free Wi-Fi 
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.free_wifi == 'No' || roomData.free_wifi == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                    Free Wi-Fi 
                                  
                                </div>
                                        <!-- Wi-Fi -->

                                        <!-- Parking 2whelers -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.parkingspacefor2whelers == 'No' || roomData.parkingspacefor2whelers == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                   Parking Space 2 wheelers
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.parkingspacefor2whelers == 'Yes' || roomData.parkingspacefor2whelers == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                   Parking Space 2 wheelers
                                  
                                </div>
                                         <!-- Parking 2whelers -->

                                            <!-- Parking 4whelers -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.parkingspacefor4whelers == 'Yes' || roomData.parkingspacefor4whelers == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                   Parking Space for 4 wheelers
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.parkingspacefor4whelers == 'No' || roomData.parkingspacefor4whelers == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                   Parking Space for 4 wheelers 
                                  
                                </div>
                                 <!-- Parking 4whelers -->

                                 <!-- Gym -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.gym == 'Yes' || roomData.gym == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                 Pure drinking water
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.gym == 'No' || roomData.gym == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                   Pure drinking water
                                  
                                </div>
                                  <!-- Gym -->

                                  <!-- RESTURANT -->
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.gym == 'Yes' || roomData.gym == 'yes'">
                                    <i class="fas fa-check-circle text-success"></i> &nbsp;
                                    LED tv (32 inch )
                                  
                                </div>
                                <div class="card-body" style="font-size: large;" *ngIf="roomData.gym == 'No' || roomData.gym == 'no'">
                                    <i class="fas fa-times-circle text-danger"></i> &nbsp;
                                    LED tv (32 inch )
                                  
                                </div>
                                   <!-- RESTURANT -->
                            </div>
                          </div>
                      </div>
                     
                      
                   
                </div>

                <div class="col-sm-6">
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
                      
                          <div class="card">
                            <div class="card-header">
                              <h2 class="text-success">{{roomData.room_type}} </h2>
                            </div>
                            <div class="card-body" style="font-size: large;">
                                <i class="fas fa-user-friends"></i> &nbsp;
                                {{roomData.adults}}
                              
                            </div>
                            <div class="card-body" style="font-size: large;">
                                <i class="fas fa-clock"></i> &nbsp;
                           Check-in: <strong class="text-danger"> {{roomData.chekintime}}</strong>
                              
                            </div>

                            <div class="card-body" style="font-size: large;">
                                <i class="fas fa-clock"></i> &nbsp;
                           Check-out: <strong class="text-danger">{{roomData.checkouttime}}</strong>
                              
                            </div>

                            <div class="card-body" style="font-size: large;">
                                <i class="fas fa-bed"></i> &nbsp;
                                {{roomData.bed_type}}
                              
                            </div>

                          </div>
                      </div>
                     
                      
                   
                </div>
                <div class="col-sm-6">
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
                      
                          <div class="card">
                            <div class="card-header">
                              <h2 class="text-success">Price : &nbsp;<i class="fas fa-rupee-sign"></i> {{roomData.price}}  </h2>
                            
                            </div>
                          </div>

                 
                      </div>
                </div>
                <!-- {{newdATA}} -->
                <div class="col-sm-6">
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
                      
                        <div class="mapouter pl-5"><div class="gmap_canvas"><iframe width="1500" height="490" id="gmap_canvas" src="https://maps.google.com/maps?q=Hydrabad&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://embedgooglemap.net/maps/4"></a><br><style>.mapouter{position:relative;text-align:right;height:490px;width:1020px;}</style><a href="https://www.embedgooglemap.net">google map website</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:490px;width:1020px;}</style></div></div>
                      </div>
                </div>
                </div>
            </div>
       
    </div>
</div>




