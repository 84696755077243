import { Component, OnInit } from '@angular/core';
import { ManageRoomsService } from '../../services/managerooms.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  updateProfile:FormGroup;
  userDetails:any[];
  constructor(private roomService:ManageRoomsService, private formBuilder:FormBuilder,
    private spinner:NgxSpinnerService) { }

  ngOnInit(): void {

    this.updateProfile = this.formBuilder.group({
      name: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      area: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      city: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      state: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      country: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      mobile_no: ['', [Validators.required, Validators.minLength]]
    })
    this.profileDetails();
  }

  get f() { return this.updateProfile.controls; }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  profileDetails(){
    this.spinner.show();
    this.userDetails = [];
    this.roomService.userDetails().subscribe( response => {
      this.userDetails = response['data'];
      this.updateProfile.controls['name'].patchValue(response['data'][0].name);
      this.updateProfile.controls['area'].patchValue(response['data'][0].area);
      this.updateProfile.controls['city'].patchValue(response['data'][0].city);
      this.updateProfile.controls['state'].patchValue(response['data'][0].state);
      this.updateProfile.controls['country'].patchValue(response['data'][0].country);
      this.updateProfile.controls['mobile_no'].patchValue(response['data'][0].mobile_no);
      console.log(this.userDetails);
      this.spinner.hide();
    })
  }

  onSubmitUpdate(model:FormGroup){
    this.spinner.show();
    this.roomService.updateProfileDetails(model.value).subscribe(response => {
      this.spinner.hide();
      swal.fire(
        response.message,
        'success'
      ).then(function(){
        location.reload();
      });
    },
    error => {
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 
     });
  }
}
