<mat-nav-list>
    <div class="profile-card">
        <div class="header">
            <h2>NAME</h2>
            <h4>example@email.com</h4>
        </div>
        <img src ="https://ichef.bbci.co.uk/news/410/cpsprodpb/4224/production/_108523961_cs1.jpg" alt="Soufiane FADIL">
    </div>

    <mat-divider></mat-divider>

    

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/default/dashboard">Dashboard</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/default/profile">profile</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/default/manage-rooms">Manage rooms</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/default/booked">Booked</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/default/admin-feedback">Booked</a>


    

</mat-nav-list>
