import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  Name = '';
  Email = '';
  sideBarOpen = true;

  constructor() { }

  ngOnInit() { 
    this.Name = JSON.parse(sessionStorage.getItem("userdata")).name;
    this.Email = JSON.parse(sessionStorage.getItem("userdata")).email_id;
  }


  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

}