import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import appConstants from './config/app.constants';


@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private myRoute: Router) {}

  setLoginDetails(data) {
    // sessionStorage.setItem('userdata',JSON.stringify(data));
    sessionStorage.setItem('userdata', JSON.stringify(data['data'][0]));
  }
  setLoginToken(token: Object){
    sessionStorage.setItem('token',JSON.stringify(token));
  }
  getLoginDetails() {
    return sessionStorage.getItem("userdata");
  }
  isLoggednIn() {
    return this.getLoginDetails() !== null;
  }
  

 
  logout() {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear();
    this.myRoute.navigate(["login"]);
  }
}
