<div class="container">
  <ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true"><p style="color: white" > Loading... </p>
</ngx-spinner>
  <div class="card mt-3 shadow">
    <div class="card-header">
      <h4>Manage Rooms</h4>
    </div>
    <div class="card-body">
      <p>
          <input type="text"  class="form-control" [(ngModel)]="filterTerm" placeholder="Search..." >
          <!-- (keyup)="applyFilter($event.target.value)" -->
          <!-- <input type="text" class="form-control"  placeholder="Enter Mobile for Search" 
                        [(ngModel)]="filterCode" (keypress)="numberOnly($event)"> -->
    </p>
      <!-- <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterTerm"> -->
 
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Booking Id</th>
            <!-- <th>Booking Name</th> -->
            <!-- <th>Gender</th> -->
            <!-- <th>Email Id</th> -->
            <th>Mobile</th>
            <th>Branch</th>
            <!-- <th>Address</th> -->
            <!-- <th>Check In</th> -->
            <th>Check Out</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Aadhar</th>
            <th>Person Image</th>
            <th>Other Proof</th>
            <!-- <th>Adults</th>
            <th>Children</th>
            <th>Rooms</th> -->
            <!-- <th>Rent</th> -->
            <!-- <th>Room No</th> -->
            
            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of bookedRooms | grdFilter:filterTerm | paginate: { itemsPerPage: 5, currentPage: p }; let i=index" >
          <tr>
            <td>{{item.Booking_id}}</td>
            <!-- <td>{{item.booking_name}}</td> -->
            <!-- <td>{{item.gender}}</td> -->
            <!-- <td>{{item.email_id}}</td> -->
            <td>{{item.mobile}}</td>
            <td>{{item.Branch}}</td>
            <!-- <td>{{item.address}}</td> -->
            <!-- <td>{{item.checkindate | date:'y-M-d'}}</td> -->
            <td>{{item.Checkout}}</td>
            <td>{{item.Amount}}</td>
            <td>{{item.Payment_method}}</td>
            <!-- <td>{{item.adults}}</td>
            <td>{{item.childrens}}</td>
            <td>{{item.rooms}}</td> -->
            <!-- <td>{{item.rent}}</td> -->
            <!-- <td>{{item.room_no}}</td> -->
           
            <td style='white-space: nowrap'>
              <button class="btn btn-primary" (click)="openViewaadhar(item.aadhar)"><i class="fa fa-eye"></i></button>
              <button class="btn btn-success" (click)="downloadPdf(item.aadhar,'aadhar')" ><i class="fa fa-download"></i></button>
            </td>
            <td style='white-space: nowrap'>
              <button class="btn btn-primary" (click)="openViewimg(item.person_image)"><i class="fa fa-eye"></i></button>
              <button class="btn btn-success" (click)="downloadPdf2(item.person_image,'person_image')" ><i class="fa fa-download"></i></button>
            </td>
            <td style='white-space: nowrap'>
                <button class="btn btn-primary" (click)="openViewproof(item.Proof)"><i class="fa fa-eye"></i></button>
                <button class="btn btn-success" (click)="downloadPdf3(item.Proof,'other_proof')" ><i class="fa fa-download"></i></button>
            </td>
            <td style='white-space: nowrap'>
              <button class="btn btn-primary" (click)="openViewModal(item)"><i class="fa fa-eye"></i></button>
              <button class="btn btn-warning" (click)="openEditModal(item)"><i class="fa fa-pencil-square-o"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div>


<div class="backdrop" [ngStyle]="{'display':sigdisplay}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplay}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><strong>View Details</strong></h4>
              <button type="button" class="close" (click)="hideViewModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form >
          <div class="modal-body">
              <table class="table table-bordered">
                
                <tbody >
                  <tr>
                    <th>Booking Name:</th>
                    <td>{{bookingName}}</td>
                  </tr>
                  <tr>
                    <th>Gender:</th>
                    <td>{{gender}}</td>
                  </tr>
                  <tr>
                    <th>Mobile:</th>
                    <td>{{mobile}}</td>
                  </tr>
                  <tr>
                    <th>Address:</th>
                    <td>{{address}}</td>
                  </tr>
                  <tr>
                    <th>Check-in:</th>
                    <td>{{checkIn | date:'dd/MM/yyyy'}}</td>
                  </tr>
                  <tr>
                    <th>Check-out:</th>
                    <td>{{checkOut | date:'dd/MM/yyyy'}}</td>
                  </tr>
                  <tr>
                    <th>Rent:</th>
                    <td>{{rent}}</td>
                  </tr>
                  <tr>
                    <th>Room No:</th>
                    <td>{{roomNo}}</td>
                  </tr>
                  <tr>
                    <th>Branch:</th>
                    <td>{{branch}}</td>
                  </tr>
                  <tr>
                    <th>Room Type:</th>
                    <td>{{roomType}}</td>
                  </tr>
                  <tr>
                    <th>Aadhar:</th>
                    <!-- <td>{{aadhar}}</td> -->
                    <td><button class="btn btn-success" (click)="aadharView()">View</button></td>
                  </tr>
                  <tr>
                    <th>Person Image:</th>
                    <!-- <td>{{personImage}}</td> -->
                    <td><button class="btn btn-success" (click)="imageView()">View</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="modal-footer">
           
              <button type="button" class="btn btn-secondary" (click)="hideViewModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Close</button>
              <button type="submit" class="btn btn-success"><span class="glyphicon glyphicon-send" aria-hidden="true"></span> Submit</button>
      
      
            </div> -->
          </form>
    
       
        
          </div>
      </div>
  </div> 
  
  <!--END START Modal -->


  <div class="backdrop" [ngStyle]="{'display':sigdisplayedit}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplayedit}">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content pt-3 pb-3">
        <div class="modal-header">
          <h4 class="modal-title"><strong>Update</strong></h4>
              <button type="button" class="close" (click)="hideEditModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form [formGroup]="updateData" (ngSubmit)="updateForm(updateData)">
          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Checkout Date:</label>
            <div class="col-sm-5">
              <!-- <input formControlName="Booking_id" class="form-control" hidden> -->
              <select formControlName="checkout_status" class="form-control">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
              </select>
              <owl-date-time #dtPicker4></owl-date-time>
            </div>

          </div>
          
            

        <button type="submit" class="btn btn-success ml-3">submit</button>
        </form>
    
       
        
          </div>
      </div>
  </div> 


  