<ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

<!-- START NAVBAR -->
<div class="navbar navbar-default navbar-fixed-top menu-top">
	<div class="container">
		<div class="navbar-header">
			<button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navBarToggle">
				<!-- <span class="sr-only">Toggle navigation</span> -->
				<span class="fa fa-navicon naviClr"></span>
				<!-- <span class="icon-bar"></span>
				<span class="icon-bar"></span> -->
			</button>
			<!--  <a href="index.html" class="navbar-brand"><img src="assets/img/logo.png" alt="logo"></a>-->
		</div>
		<div class="navbar-collapse collapse" id="navBarToggle">
    
      
         <nav>
        
				<!-- <ul class="nav navbar-nav navbar-right contactUs-nav"> -->
          <ul class="nav navbar-nav navbar-right mt-3" >
            <div class="row">
					<li>
						<a class="page-scroll navItem" href="index.html">Home</a>
          </li>
           &nbsp;&nbsp;&nbsp; &nbsp; 
					<!-- <li>
						<a class="page-scroll navItem" href="#ourservice">Our Services</a>
					</li>
					<li>
						<a class="page-scroll navItem" href="#Portfolio">Portfolio</a>
					</li> -->
					<!-- <li>
						<a class="page-scroll navItem" href="#">Rooms</a>
					</li> -->
        </div>
        </ul>
        
			</nav>
		</div>
	</div>
	<!--- END CONTAINER -->
</div>
<!-- END NAVBAR -->




<!-- START HOME -->
<!-- <div id="contactResponsive"> -->
	<!-- <section>
    <div class="container" style="margin-top: 3em;">
    <div class="row">  
      <div class="col-md-3"> </div>
      <div class="col-md-6">  
      <div class="col-md-4 cardView"  *ngFor = "let item of data">
          <div class="card"> 
          <div>         
            <img class="rounded mx-auto d-block img-responsive mt-3" src="{{item.product_image}}" alt="Card image cap">
            </div>
            <div class="card-body">
              <h6 class="card-title" style="font-size: 14px;">{{item.product_name}}</h6>
             
              <div class="row">
                
                <div class="col-md-6">
                  <p class="card-text"> <small> {{item.room_type}} Room </small></p>
                </div>
                <div class="col-md-6">
                <p class="text-right"> <small> Rs {{item.amount}} </small> </p>
                </div>
              </div>
              <button class="btn btn-lg btn-primary" (click)="showModal(item)">Book</button>
            </div>
          </div>
        </div> </div>  <div class="col-md-3"> </div>
      </div>
    </div>
	</section> -->
  <section>
    <div class="container">
      <div class="card">
          <div class="card-body">
              
            <!-- *ngIf="value.status === 'booked'" -->

              <div *ngFor="let hd of groupArr">
                  <div class="card-header bg-secondary">
                      <h4 class="text-center text-white">FLOOR {{hd.floor_no}}</h4>
                  </div>
                  <div class="row card-group">
  
                      <div class="col-3" *ngFor="let value of hd.groupItem">

                          <div class="card">
  
                              <a routerLink="/img-info/{{value.room_no}}" ><img src="{{value.image_link}}" alt="Avatar" style="width:100%"></a>
                              <h4 class=""><b>{{value.room_type}}</b></h4>
                              <div class="row">
                                  <div class="col-md-6">
                                      <h4 class="ml-5">Room No<p class="text-success">{{value.room_no}}</p>
                                      </h4>
                                  </div>
                                  <div class="col-md-6">
                                      <h4 class="ml-5">Price<p class="text-success">{{value.amount}}</p>
                                      </h4>
                                  </div>
                                  <div class="col-md-6" *ngIf="value.status === 'booked' ">
                                    <!-- <h4 class="ml-5">Status<p class="text-success">{{value.status}}</p>
                                    </h4> -->
                                    <h4 class="ml-5" >Status<p class="text-danger" >{{value.status}}</p>
                                      <!-- <h4 class="ml-5" *ngIf="value.status === 'Available'" >Status<p class="text-success" >{{value.status}}</p> -->
                                    </h4>
                                </div>
                                <div class="col-md-6" *ngIf="value.status === 'Booked'">
                                  <!-- <h4 class="ml-5">Status<p class="text-success">{{value.status}}</p>
                                  </h4> -->
                                  <h4 class="ml-5" >Status<p class="text-danger" >{{value.status}}</p>
                                    <!-- <h4 class="ml-5" *ngIf="value.status === 'Available'" >Status<p class="text-success" >{{value.status}}</p> -->
                                  </h4>
                              </div>
                                <!-- /AVAILABLE -->
                                <div class="col-md-6" *ngIf="value.status === 'Available'">
                                  <!-- <h4 class="ml-5">Status<p class="text-success">{{value.status}}</p>
                                  </h4> -->
                                  <h4 class="ml-5" >Status<p class="text-success" >{{value.status}}</p>
                                    <!-- <h4 class="ml-5" *ngIf="value.status === 'Available'" >Status<p class="text-success" >{{value.status}}</p> -->
                                  </h4>
                              </div>
                              

                              </div>
  
                              <button class="btn btn-success float-right mb-2" (click)="showModal(value)" *ngIf="value.status === 'Available'">Book</button>
  
  
                          </div>
                      </div>
                     
                      
                  </div>
              </div>
          </div>
      </div>
    </div>
    </section>
  
<!-- (click)="openModal(item)" -->
  <section id="contact">
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-4">
            <table cellspacing="20px">
              
    
              <tr> <td class="style-title">Address1:</td> </tr>
              <tr> <td class="style-title">Tenali</td> </tr>
              <tr> <td class="style-text">157,Salipeta,Tenali,</td> </tr>
              <tr> <td class="style-text"> Guntur District,Andhra Pradesh- 522201</td> </tr>
              <!-- <tr> <td class="style-title">Hyderabad</td> </tr>
              <tr> <td class="style-text">7-1-211/28,Opp.Indian Bank,D.K Road,Ameerpet,Hyderabad Telangana- 500038</td> </tr>
               -->
            
    
    
            </table>
    
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4 text-center wow zoomIn">
            <div class="footer_logo">
              <a href="index.html">
                <p class="footer_txt resFooter">V Guestin</p>
              </a>
            </div>
            <div class="footer_social">
              <ul>
                <li>
                  <a target="_Blank" class="f_facebook" href="">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_Blank" class="f_whatsapp" href="https://web.whatsapp.com/send?phone=+919949633109">
                    <!-- <a class="f_whatsapp" href="intent://send/7639900033#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end"> -->
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a class="f_skype" href="skype:live:narendra.vinodala@gmail.com?call">
                    <i class="fa fa-skype"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="copyright">
              <p>Copyright &copy; 2020 | Accuracy.</p>
            </div>
            <!--- END FOOTER COPYRIGHT -->
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4">
            <table cellspacing="20px">
              
    
              <tr> <td class="style-title">Address2:</td> </tr>
              <!-- <tr> <td class="style-title">Tenali</td> </tr>
              <tr> <td class="style-text">157,Salipeta,Tenali,</td> </tr>
              <tr> <td class="style-text"> Guntur District,Andhra Pradesh</td> </tr></td> </tr> -->
              <tr> <td class="style-title">Hyderabad</td> </tr>
              <tr> <td class="style-text">7-1-211/28,Opp.Indian Bank,D.K Road,</td> </tr>
              <tr> <td class="style-text"> Ameerpet,Hyderabad Telangana- 500038</td> </tr> 
              
            
    
    
            </table>
          </div>
          <!--- END COL -->
        </div>
        <!--- END ROW -->
      </div>
      <!--- END CONTAINER -->
    </div>
    <!-- END FOOTER -->
    </section>










    <div class="backdrop" [ngStyle]="{'display':sigdisplay}"></div>
      <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplay}">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Booking</h4>
                <button type="button" class="close" (click)="hideModal()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
          </div>
    
          <form class="form-horizontal" [formGroup]="booking"
          (ngSubmit)="addBooking(booking)">
          <div class="modal-body" >
            <div style="display: none;"class="form-group col-md-4">
              <label for="room">Room No<span class="text-danger">*</span></label>
              
                <input type="text" class="form-control" id="room_no" formControlName="room_no" disabled>
            </div>
            <h2><b style="color: #fab903;">Room No:</b> {{roomNo}}</h2>
            <div class="row">
              
              <div class="form-group col-md-6">
                <label for="name">Name <span class="text-danger">*</span></label>
                <input type="email" class="form-control" id="name" name="name" formControlName="name">
                <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                  <p *ngIf="f.name.errors.required" class="text-danger">
                   Name is Required
                  </p>
                  <p *ngIf="f.name.errors.pattern" class="text-danger">
                    Only alphabets allowed
                   </p>
              </div>
              </div>

              <div class="form-group col-md-6">
                <label for="gender" class="col-sm-3">Gender<span class="text-danger">*</span></label>
                <div class="col-sm-2">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="male" value="Male" class="custom-control-input" formControlName="gender">
                    <label class="custom-control-label" for="male">Male</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="female" value="Female" class="custom-control-input" formControlName="gender">
                    <label class="custom-control-label" for="female">Female</label>
                  </div>

            </div>
            </div>
            </div>
            <div class="row">
              
              <div class="form-group col-md-6">
                <label for="email">Email <span class="text-danger">*</span></label>
                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email">
              </div>

              <div class="form-group col-md-6">
                <label for="mobile">Mobile <span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">+91</span>
                <input type="text" class="form-control" id="mobile"  formControlName="mobile" maxlength="10" placeholder="Mobile">
              </div>
            </div>
              </div>
            </div>
            <div class="row">
            <div class="form-group col-md-11">
              <label for="address">Address <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="address" placeholder="Address" formControlName="address">
            </div>
          </div>     

          <div class="row">
              
            <div class="form-group col-md-6">
              <label for="checkin">Check-In Date <span class="text-danger">*</span></label>
              <input type="text"
                      placeholder="Check-In Date"
                      class="form-control"
                      formControlName="checkin"
                      readonly> 

            </div>

            <div class="form-group col-md-6">
              <label for="checkout">Check-Out Date <span class="text-danger">*</span></label>
              
                       <input type="text"
                      placeholder="Check-Out Date"
                      class="form-control"
                      formControlName="checkout"
                      bsDatepicker [minDate]="checkoutMinDate"
                      [bsConfig]="{ adaptivePosition: true }" required>          
            </div>
          </div>

          <div class="row">
              
            <div class="form-group col-md-4">
              <label for="branch">Branch <span class="text-danger">*</span></label>
              <select formControlName="branch" class="form-control" type="text" required="required" disabled>
                <option [ngValue]="null">Select</option>
                  <option *ngFor="let item of branchList" value="{{item.branch_name}}">{{item.branch_name}}</option>
               
                </select>
            </div>
            <div class="form-group col-md-4">
              <label for="room">Room Type <span class="text-danger">*</span></label>
              <!-- <select formControlName="room_type" class="form-control" type="text" required="required" (change)="onChangeRoomType($event.target.value)">
                <option [ngValue]="null">Select</option>
                  <option *ngFor="let item of roomType" value="{{item.room_type}}">{{item.room_type}}</option>
               
                </select> -->
                <input type="text" class="form-control" id="room_type" formControlName="room_type" disabled>
            </div>
            <div class="form-group col-md-4">
              <label for="rooms">No of Rooms <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="rooms" formControlName="rooms" (keyup)="onKeyUpChange($event.target.value)">
            </div>
          </div>

          <div class="row">
              
            <div class="form-group col-md-4">
              <label for="adults">Adults <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="adults" formControlName="adults" readonly>
            </div>
            <div class="form-group col-md-4">
              <label for="childrens">Childrens <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="childrens" formControlName="childrens" readonly>
            </div>
            <div class="form-group col-md-4">
              <label for="rent">Rent <span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="rent" formControlName="rent" readonly>
            </div>
          
          </div>
          <div class="row">
            <!-- <div class="form-group col-md-4">
              <label for="amount">Total Amount<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="amount" formControlName="totalAmount" readonly>
            </div> -->
            <h2><b style="color: #fab903;">Total Fare:</b> {{calculated}}</h2>
          </div>

         </div>
          
         <div class="modal-footer">
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="!booking.valid">Submit</button>
           <button type="button" class="btn btn-sm btn-danger" (click)="hideModal()" style="background: #d43f3a !important;">Close</button>
     
         </div>
        </form>
         
          
            </div>
        </div>
    </div> 
    
    <!--END START Modal -->
  

    