<ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
[fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>



   <section id="booking">
    <div class="container">
        <div class="section-title text-center wow zoomIn">
            <div class="navbar-collapse collapse" id="navBarToggle">
                <nav>
                    <ul class="nav navbar-nav navbar-right mt-3">
                        <div class="row">
                            <li>
                                <a class="page-scroll navItem" href="index.html" style="font-size: xx-large; color: #ffb900;">Home</a>
                            </li>
                            &nbsp;&nbsp;&nbsp; &nbsp;
                        </div>
                    </ul>
                </nav>
            </div>
            <br>
            <h2>My Bookings</h2>
            <div class="line"></div>
        </div>
        <div class="row">

            <!-- planner-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 planner">
                <div class="planner-block">
                    <form class="form-planner form-horizontal" [formGroup]="searching" (ngSubmit)="onSearching(searching)">
                        <div class="row">
                            <div class="col-lg-1 col-md-1 col-sm-12"></div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div class="form-group">
                                    <label>Booking ID</label>

                                    <input type="text" placeholder="Booking ID" class="form-control"
                                        formControlName="bookingId">
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div class="form-group">
                                    <label>Mobile No</label>
                                    <div class="theme-select">
                                        <input type="text" placeholder="Mobile No" class="form-control"
                                            formControlName="mobileNo">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="planner-check-availability">
                                    <button type="submit" class="btn btn-lg btn-primary"
                                        style="margin-left: 24px;">Submit</button>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-12"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="container" *ngIf="opentable">
    <div class="row">
        <div class="col-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Booking ID</th>
                        <th scope="col">Booking Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Address</th>
                        <th scope="col">Check In</th>
                        <th scope="col">Check Out</th>
                        <th scope="col">Rent</th>
                        <th scope="col">Branch</th>
                        <th scope="col">Room Type</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of data">
                    <tr>
                        <td>{{item.booking_id}}</td>
                        <td>{{item.booking_name}}</td>
                        <td>{{item.gender}}</td>
                        <td>{{item.mobile}}</td>
                        <td>{{item.address}}</td>
                        <td>{{item.checkindate | date:'y-M-d'}}</td>
                        <td>{{item.checkoutdate | date:'y-M-d'}}</td>
                        <td>{{item.rent}}</td>
                        <td>{{item.branch}}</td>
                        <td>{{item.room_type}}</td>
                        <td>
                            <button type="button" class="btn btn-danger"
                                (click)="cancelBooking(item.booking_id)">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div class="backdrop" [ngStyle]="{'display':sigdisplay3}"></div>

<div class="modal" [ngStyle]="{'display':sigdisplay3}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Cancellation</h5>
                <button type="button" class="close" (click)="hideDeleteModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="Cancellation" (ngSubmit)="onSubmitCancel(Cancellation)">
                <div class="modal-body">
                    <input type="hidden" formControlName="book_id" class="form-control" required>
                    <h5>Are you sure want to cancel selected room ?</h5>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="hideDeleteModal()"><span
                            class="glyphicon glyphicon-remove" aria-hidden="true"></span> No</button>
                    <button type="submit" class="btn btn-info"><span class="glyphicon glyphicon-ok"
                            aria-hidden="true"></span> Yes</button>
                </div>
            </form>
        </div>
    </div>
</div>



