import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Apps from '../config/app.constants';
// import * as moment from 'moment';

// import 'rxjs/Rx';
// import 'rxjs/add/operator/map';
import { Observable } from "rxjs";
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
// @Injectable()
export class ManageRoomsService {
    private apiCreateBookingInfo = Apps.apiBaseUrl +'create_booking_info';
    private apiViewRooms = Apps.apiBaseUrl + 'view_rooms_by_admin';
    private apiUpdateBooking = Apps.apiBaseUrl + 'update_status';
    private apiDeleteRoom = Apps.apiBaseUrl + 'delete_rooms';
    private apiDeleteReport = Apps.apiBaseUrl + 'delete_report';
    private apiGetStatus = Apps.apiBaseUrl + 'get_room_status';
    private apiGetBranch = Apps.apiBaseUrl + 'get_branch_info';
    private apiGetRoomType = Apps.apiBaseUrl + 'get_room_type';
    private apiUpdatePass = Apps.apiBaseUrl + 'updatepass';
    private apiGetBookedRooms = Apps.apiBaseUrl + 'view_booked_rooms_by_admin';

    private apiGetDailyReports = Apps.apiBaseUrl + 'dailyreports';

    private apiUserDetails = Apps.apiBaseUrl + 'user_details_by_mailid';
    private apiUpdateDetails = Apps.apiBaseUrl + 'update_profile';
    private apiAddFeedback = Apps.apiBaseUrl + 'feedback';
    private apiMyBooking = Apps.apiBaseUrl + 'my-booking';
    private apiGetAllFeedbacks = Apps.apiBaseUrl + 'admin_feedback';
    private apiCheckOutDate = Apps.apiBaseUrl + 'checkout';
    private apiReview = Apps.apiBaseUrl + 'verify';

    private apifilterReports = Apps.apiBaseUrl + 'filter_reports';
    private apihotelSignup = Apps.apiBaseUrl + 'hotel_signup';
    // private apihotelSignup = Apps.apiBaseUrl + 'hotel_signup';

  



  headers= new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8'
  });
  
  options = { headers: this.headers };
  
  constructor(private http:HttpClient,
     ) { }

     getFeedbacks(){
      return this.http.get(this.apiGetAllFeedbacks);
    }

  getRooms(){
    return this.http.get(this.apiViewRooms);
  }

  getStatus(){
    
    return this.http.get(this.apiGetStatus);
  }

  getBranches(){
    return this.http.get(this.apiGetBranch);
  }

  getRoomType(roomType){
    let requestData = {};
    requestData = {
     
      room_type:roomType,
      
    } 
    console.log(requestData);
    return this.http.post(this.apiGetRoomType, requestData);
  }

  getBookedRooms(){
    return this.http.get(this.apiGetBookedRooms);
  }

  getdailyreports(){
    return this.http.get(this.apiGetDailyReports);
  }


  postCreateBookings(formData):Observable<any>{
      let requestData = {};
      requestData = {
        room_no:formData.roomNo,
        room_type:formData.roomType,
        status:formData.Status,
        amount:formData.Amount,
        branch:formData.Branch,
        floor_no:formData.floorNo,
        image_link:formData.imageLink2
      }

      return this.http.post(this.apiCreateBookingInfo, requestData);
  }
  
  postUpdateBookings(formData):Observable<any>{
    let requestData = {};
    requestData = {
      room_no:formData.roomNo1,
      room_type:formData.roomType1,
      status:formData.Status1,
      amount:formData.Amount1,
      branch:formData.Branch1,
      floor_no:formData.floorNo1,
      image_link:formData.imageLink1,
      image_link1:formData.imageLink2,
      image_link2:formData.imageLink3,
      image_link3:formData.imageLink4,
      image_link4:formData.imageLink5,
      air_conditioning: formData.AirConditioning,
      room_service: formData.room_service,
      free_wifi: formData.wifi,
      parkingspacefor2whelers: formData.parking2,
      parkingspacefor4whelers: formData.parking4,
      gym: formData.gym,
      restaurant: formData.restaurant,
      adults: formData.adults,
      chekintime: formData.checkin,
      checkouttime: formData.checkout,
      bed_type: formData.bed_type,
      price: formData.price,
    }
    return this.http.post(this.apiUpdateBooking, requestData);
}

postDeleteRoom(formData):Observable<any>{
  let requestData = {};
  requestData = {
    room_no:formData.roomNo2
  }
  return this.http.post(this.apiDeleteRoom, requestData);
}
  
updatePassword(formData):Observable<any>{
  let requestData = {};
  requestData = {
    email_id: formData.Email5,
    password: formData.confirmPassword
  }
  return this.http.post(this.apiUpdatePass, requestData);
}

userDetails():Observable<any>{
  let requestData = {};
  requestData = {
    email_id:JSON.parse(sessionStorage.getItem("userdata")).email_id 
  }
  // console.log(requestData);
  return this.http.post(this.apiUserDetails, requestData);
}

updateProfileDetails(formData):Observable<any>{
  let requestData = {};
  requestData = {
    email_id:JSON.parse(sessionStorage.getItem("userdata")).email_id,
    name:formData.name,
    city:formData.city,
    state:formData.state,
    area:formData.area,
    mobile_no:formData.mobile_no.toString()
  }
  // console.log(requestData);
  return this.http.post(this.apiUpdateDetails, requestData);
}

postFeedback(formData):Observable<any> {
  let requestData = {};
  requestData = {
    message: formData.message,
    Review: formData.review,
    Ever_visited: formData.everVisited,
    email_ID: formData.emailId,
    Mobile_No: formData.mobileNo,
  }
  console.log(requestData);
  return this.http.post(this.apiAddFeedback, requestData);
}

postCheckOutDate(formData, bookingId):Observable<any> {
  let requestData = {};
  requestData = {
    checkout_status: formData.checkout_status,
    Booking_id: bookingId,
    Amount:formData.Amount,
    Payment_method:formData.Payment_method,
    Name:formData.Name,
    Room_no:formData.Room_no

  }
  console.log(requestData);
  return this.http.post(this.apiCheckOutDate, requestData);
}

postReview(formData, bookingId):Observable<any> {
    console.log(formData);
    let requestData = {};
    requestData = {
        // checkout_status: formData.checkout_status,
        Booking_id: bookingId,
        // Adharnumber: formData.Adharnumber,
        Adhar_number1: formData.Adhar_number1,
        Adhar_number2: formData.Adhar_number2,
        Amount: formData.Amount,
        Branch: formData.Branch,
        // Name: formData.Name,
        Person_name1: formData.Person_name1,
        Person_name2: formData.Person_name2,
        Payment_method: formData.Payment_method,
        mobile: formData.mobile
    }
    console.log(requestData);
    return this.http.post(this.apiReview, requestData);
}

deleteData(formData):Observable<any>{
  let requestData = {};
  requestData = {
    Booking_id: formData.id,
  }
  return this.http.post(this.apiDeleteReport, requestData);
}

postfilter(formData):Observable<any> {
    // let requestData = {};
    // requestData = {
    //   checkout_status: formData.checkout_status,
    // }
    console.log(formData);
    return this.http.post(this.apifilterReports, formData);
}

posthotelSignup(formData):Observable<any>{
  let requestData = {};
  requestData = {
    name: formData.name,
    email_id: formData.email_id,
    password: formData.password,
    area: formData.area,
    city: formData.city,
    state: formData.state,
    country: formData.country,
    mobile_no: formData.mobile_no,
    user_type: "admin"

}
  console.log(requestData);
  return this.http.post(this.apihotelSignup, requestData);
}

}

