import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageRoomsService } from '../../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import swal from 'sweetalert2';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-booked',
  templateUrl: './booked.component.html',
  styleUrls: ['./booked.component.scss']
})
export class BookedComponent implements OnInit {
  // @ViewChild('UpdateEvents',null) UpdateEvents: NgForm;
  // 
  filterTerm: "";
  filterCode:any;
  p: number = 1;
  bookedRooms:any=[];
  updateData;
  bookingId = '';
  sigdisplay = 'none';
  sigdisplayedit = 'none';
  public filterData: any = [];
  public bookingName;
  public gender;
  public mobile;
  public address;
  public checkIn;
  public checkOut;
  public rent;
  public roomNo;
  public branch;
  public roomType;
  public aadhar;
  public personImage;
  // public startDate = new FormControl(new Date());

  constructor(private roomService:ManageRoomsService,
    private formBuilder:FormBuilder, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {  
    this.getBookedRooms();

    this.updateData = this.formBuilder.group({
        checkout_status:[''],
        Booking_id:['']
    //   checkDate:['']
    })
  }
  getBookedRooms(){
    this.spinner.show();
    this.bookedRooms = [];
    this.roomService.getBookedRooms().subscribe(response => {
        console.log(response,'table data')
      this.bookedRooms = response['data'];
      this.filterData = response['data'];
      console.log(this.bookedRooms);
      this.spinner.hide();
    })
  }

  openViewModal(e){
    this.sigdisplay = 'block';
    console.log(e);
    this.bookingName = e.booking_name;
    this.gender = e.gender;
    this.mobile = e.mobile;
    this.address = e.address;
    this.checkIn = e.checkindate;
    this.checkOut = e.checkoutdate;
    this.rent = e.rent;
    this.roomNo = e.room_no;
    this.branch = e.branch;
    this.roomType = e.room_type;
    this.aadhar = e.aadhar;
    this.personImage = e.person_image;
  }

  
  hideViewModal(): void {
    this.sigdisplay = 'none';
  }

  openEditModal(e){
    this.sigdisplayedit = 'block';
    this.bookingId = e.Booking_id;
    console.log(e);
  }

  hideEditModal(): void{
    this.sigdisplayedit = 'none';
  }
  openViewaadhar(event) {
    window.open(event, "_blank");
  }
  openViewimg(event) {
    window.open(event, "_blank");
  }
  openViewproof(event) {
    window.open(event, "_blank");
  }
  aadharView(){
    window.open(this.aadhar, "_blank");
  }  

  imageView(){
    window.open(this.personImage, "_blank");
  }


    downloadPdf(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    downloadPdf2(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    downloadPdf3(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }


  updateForm(model:FormGroup){
    this.spinner.show();
    this.roomService.postCheckOutDate(model.value, this.bookingId).subscribe(response => {
      this.spinner.hide();
      console.log(response);
      swal.fire(
        response.message,
        'success'
      ).then(function(){
        location.reload();
      });
    },
    error => {
      this.spinner.hide();
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 

    })
  }
  applyFilter(event:string){

    // debugger;

        let filterValueLower = event.toLowerCase();
         if(event === '' ) {
             this.bookedRooms=this.filterData;
         } 
         else {
           this.bookedRooms = this.bookedRooms.filter((item) => 
                // item.Booking_id.includes(filterValueLower) || 
                item.mobile.includes(filterValueLower)); 
                // item.Checkout.includes(filterValueLower) || 
                // item.Amount.includes(filterValueLower) ||
                // item.Payment_method.includes(filterValueLower));
        //    item.Checkout.includes(filterValueLower))
         }


        //  let filterValueLower = filterValue.toLowerCase();
        //  if(filterValue === '' ) {
        //      this.employees=this.allEmployees;
        //  } 
        //  else {
        //    this.employees = this.allEmployees.filter((employee) => employee.name.includes(filterValueLower)
        //  }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


}
