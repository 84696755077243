import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { pathToFileURL } from 'url';
import { DefaultComponent} from './layouts/default/default.component';
import { DashboardComponent} from './modules/dashboard/dashboard.component';

import { from } from 'rxjs';
import { HomeComponent } from './home/home.component';

import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ManageRoomsComponent } from './modules/manage-rooms/manage-rooms.component';
import { ImgInfoComponent } from './img-info/img-info.component';
import{ BookedComponent} from './modules/booked/booked.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RoomsComponent } from './rooms/rooms.component';
import { AuthGuard } from './auth.guard';
import { FeedbackComponent } from './feedback/feedback.component';
import { SearchingComponent } from './searching/searching.component';
import { AdminFeedbackComponent } from './app/modules/admin-feedback/admin-feedback.component';
import { NewDesignComponent } from './new-design/new-design.component';

import { ChatbotComponent } from './chatbot/chatbot.component';
import { ReportsComponent } from './modules/reports/reports.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent,
  },
  {
    path:'login',
    component: LoginComponent,
  },
  {
    path:'feedback',
    component: FeedbackComponent,
  },
  {
    path:'img-info/:room_no',
    component: ImgInfoComponent,
  },
  {
    path:'searching',
    component: SearchingComponent,
  },
  {
    path:'chatbot',
    component: ChatbotComponent,
  },
  {
    path:'rooms',
    component: RoomsComponent,
  },
  {path:'design', component:NewDesignComponent},
  {
path:'default',
component : DefaultComponent, canActivate: [AuthGuard],
  
children:[{
  path:'',
component : DashboardComponent, canActivate: [AuthGuard]
},
{
  path:"dashboard",
  component : DashboardComponent, canActivate: [AuthGuard]
},
{
  path:'profile',
component : ProfileComponent, canActivate: [AuthGuard]
},
{
path: 'change-password',
component : ChangepasswordComponent, canActivate: [AuthGuard]
},
  {
  path : 'manage-rooms',
  component : ManageRoomsComponent, canActivate: [AuthGuard]
},{
  path : 'booked',
  component : BookedComponent, canActivate: [AuthGuard]
},{
  path : 'reports',
  component : ReportsComponent, canActivate: [AuthGuard]
},{
  path : 'admin-feedback',
  component : AdminFeedbackComponent, canActivate: [AuthGuard]
}
]
  
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
