import { Component, OnInit } from '@angular/core';
import { ManageRoomsService } from '../../../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-feedback',
  templateUrl: './admin-feedback.component.html',
  styleUrls: ['./admin-feedback.component.scss']
})
export class AdminFeedbackComponent implements OnInit {
  p: number = 1;
  feedbackList;
  constructor(private roomService:ManageRoomsService, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {  
    this.getFeedbacks();
  }
  getFeedbacks(){
    this.spinner.show();
    this.feedbackList = [];
    this.roomService.getFeedbacks().subscribe(response => {
      console.log(response);
      
      this.feedbackList = response['data'];
      console.log(this.feedbackList);
      this.spinner.hide();
    })
  }

}
