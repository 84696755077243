import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Apps from '../config/app.constants';
import * as moment from 'moment';

// import 'rxjs/Rx';
// import 'rxjs/add/operator/map';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
// @Injectable()
export class SiteService {
    private apiUrlGetTest = Apps.apiBaseUrl +'get_all_signup_details';
    private apiUrlLogin = Apps.apiBaseUrl +'login';
    private apiUrlAddBooking = Apps.apiBaseUrl +'hotel_booking';
    // private apiUrlCheckAvailability = Apps.apiBaseUrl +'check_totalrooms_availability'; 
    private apiUrlCheckAvailability = Apps.apiBaseUrl +'check_availability'; 
    private apiUrlRoomStatus = Apps.apiBaseUrl+ 'room_status';

 
    private apUrlApiBid = Apps.apiBaseUrl + 'api/bid';
  //  Cancel Booking
  private apiUrlGetBookedData = Apps.apiBaseUrl + 'search_room';
  private apiUrlCancelRoom = Apps.apiBaseUrl + 'cancel_room';
  private apiUrlRoomView = Apps.apiBaseUrl + 'room_view';
    post(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  
  headers= new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8 '
  });
  options = { headers: this.headers };
  
  
  commonPost(url, body): Observable<any> {
    return this.http.post(url,
      body, this.options);
  }
  
  constructor(private http:HttpClient,
     ) { }

  
  public getTest(){
    return this.http.get(this.apiUrlGetTest);
  }


  login(formData): Observable<any> {
    // console.log(formData);
    let requestData: any = [];

    requestData = {};
    requestData = { 
         email_id: formData.username,
         password: formData.password
    };    
    // console.log(requestData); 
    return this.http.post(this.apiUrlLogin, requestData);
  }


  checkAvailability(formData): Observable<any> {
    // console.log(formData);
    let requestData: any = [];

    requestData = {};
    requestData = { 
         checkindate: formData.checkIn,
         branch: formData.branch,
        //  room_type: formData.room_type
    };    
    // console.log(requestData); 
    return this.http.post(this.apiUrlCheckAvailability, requestData);
  }

 //  mobile: "+91"+formData.mobile,
  addBooking(formData): Observable<any> {
    console.log(formData);
    let requestData: any = [];

    requestData = {};
    requestData = { 
         booking_name: formData.name,
         gender: formData.gender,
         email_id: formData.email,
         mobile: formData.mobile,
         address: formData.address,
         checkindate: moment(formData.checkin).format('YYYY/MM/DD'),
         checkoutdate: moment(formData.checkout).format('YYYY/MM/DD'),
         adults: formData.adults.toString(),
         room_no: formData.room_no,
         childrens: formData.childrens.toString(),
         rooms: formData.rooms,
         rent: parseInt(formData.rent).toString(),
         branch: formData.branch,
         room_type: formData.room_type
    };     
    return this.http.post(this.apiUrlAddBooking, requestData);
  }

  apiChangeStatus(checkoutdate, room_no): Observable<any>{
    let requestData = {};
    requestData = {
      checkoutdate: moment(checkoutdate).format('YYYY/MM/DD'),
      room_no: room_no
    } 
    
    return this.http.post(this.apiUrlRoomStatus, requestData);
  }


  apiBidPayment(bookingId, mailId): Observable<any>{
    let requestData = {};
    requestData = {
        booking_id: bookingId,
        email_id: mailId
    } 
    
    return this.http.post(this.apUrlApiBid, requestData);
  }

  apiGetBookedData(data): Observable<any>{
    let requestData = {};
    requestData = {
        booking_id: data.bookingId,
        mobile: data.mobileNo
    } 
    
    return this.http.post(this.apiUrlGetBookedData, requestData);
  }

  apiCancelRoom(data): Observable<any>{
    let requestData = {};
    requestData ={
      booking_id: data.book_id
    }
    return this.http.post(this.apiUrlCancelRoom, requestData);
  }
  apiRoomView(data): Observable<any>{

    let requestData = {};
    requestData ={
      id: data
    }
    console.log(data)
    return this.http.get(this.apiUrlRoomView + "/" + data , requestData);
  }
}


