import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { MustMatch } from './must-match.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageRoomsService } from '../services/managerooms.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  forgetPassword: FormGroup;
  registerUser:FormGroup;
  mobileNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  Registration:boolean = false;
  ChangePassword:boolean = false;

  get f() { return this.forgetPassword.controls; }

  get g() { return this.registerUser.controls; }

  constructor(private myBuilder: FormBuilder, private createService:ManageRoomsService,
    private router: Router, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.registerUser = this.myBuilder.group({
      name:['',[Validators.required]],
      email_id:['',[Validators.required, Validators.email]],
      password:['',[Validators.required]],
      area:['',[Validators.required]],
      city:['',[Validators.required]],
      state:['',[Validators.required]],
      country:['',[Validators.required]],
      mobile_no:['',[Validators.required]],
     
    });

    this.forgetPassword=this.myBuilder.group({
      Email5: ['', [Validators.required, Validators.email]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },{
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    // console.log(JSON.parse(sessionStorage.getItem("userdata"))[0].email_id);
    console.log(JSON.parse(sessionStorage.getItem("userdata")).email_id);
    this.forgetPassword.controls['Email5'].patchValue(JSON.parse(sessionStorage.getItem("userdata")).email_id);
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit(model:FormGroup){
    console.log(model.value);
    this.createService.posthotelSignup(model.value).subscribe(res => {
      console.log(res);
      swal.fire(
        res.message,
        'success'
      ).then(function(){
        location.reload();
      })
    },error => {
      swal.fire(
        'Failes!',
        error,
        'error'
      ).then(function(){
        location.reload();
      })
    })
  }
 
  forgetPasswordForm( model: FormGroup){
    console.log(model.value);
   this.spinner.show();
    this.createService.updatePassword( model.value).subscribe( response => {
      console.log(response);
      
      this.spinner.hide();
      
      swal.fire(
        'Success',
        'Password Changed Successfully',
        'success'
      )
      // this.navigateTo();
    })
}
navigateTo(){
  this.router.navigate(['app-signIn']);
} 

onChangeFeature(event){
  console.log(event);
  if(event === "Registration"){
    this.Registration = true;
    this.ChangePassword = false;
  }else if(event === "Change Password"){
    this.ChangePassword = true;
    this.Registration = false;
  }else{
    this.Registration = false;
    this.ChangePassword = false;
  }
}
}
