import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import 'style-loader!./home.scss';
// import { HttpClient, Response } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageRoomsService } from '../services/managerooms.service';
import { DataService } from "../services/data.service";

import 'rxjs/add/operator/map';
// import { PopoverModule } from 'ngx-bootstrap';
// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DomSanitizer } from '@angular/platform-browser';
// import { NgxCarousel } from 'ngx-carousel';
// import { OwlModule } from 'ngx-owl-carousel';
// import {OwlCarousel} from 'ngx-owl-carousel';
import * as moment from 'moment';
import swal from 'sweetalert2';

declare var ParticleSlider: any;
// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   effect: 'cube',
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };
@Component({
    selector: 'app-home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit {
    // @ViewChild('owlElement') owlElement: OwlCarousel

    public Images;  
    public checkAvailability: FormGroup;
    roomType:any[];
  branchList:any[];
    minDate: Date;
  SlideOptions = { items: 1, dots: true, nav: true };  
//   CarouselOptions = { items: 3, dots: true, nav: true };
carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 5000,
    loop:true,
    /*responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 2,
        nav: true,
        loop: true
      },
      1500: {
        items: 3,
        nav: true,
        loop: true
      }
    }*/
  }
 
    hideLargeUp: boolean = false;
    hideMediumDown: boolean = false;
    width: number = 100;
    height: number = 100;
    position: number = 0;
    itItems: any[] = [];
    itesItems: any[] = [];
    mw_tab_e1: boolean = false;
    mw_tab_e2: boolean = false;
    mw_tab_e3: boolean = false;
    showServices: boolean = true;
    // config: SwiperConfigInterface = {};
    basePath: any;
    about: any;
    abouttitle: any;
    servicetitle: any;
    tabValue1: any;
    tabValue2: any;
    itkeys: any[] = [];
    iteskeys: any[] = [];
    tempitbrief: any;
    tempitesbrief: any;
    itbrief: any[] = [];
    itesbrief: any[] = [];
    keys: any[] = [];
    key: any = 'h1';
    mykeybrief: any;
    subitemvoice: any[] = [];
    subitemnonvoice: any[] = [];
    // public carouselOne: NgxCarousel;

    get f() { return this.checkAvailability.controls; }

    constructor(
        private router: Router, private http: HttpClient, private sanitizer: DomSanitizer,
        private data: DataService,
        private _fb: FormBuilder, private roomService: ManageRoomsService) 
        { 
            this.minDate = new Date();
        }

    ngOnInit() {
        // this.owlElement.next([200]);

        this.checkAvailability = this._fb.group({
            checkIn: ['', [Validators.required]],
            // room_type: ['', [Validators.required]],
            branch: ['', [Validators.required]]
        });

        // this.getRoomsList();
        this.getBranchList();

        this.Images = ['../assets/img/download.jpg', '../assets/img/images.jpg', '../assets/img/images_1.jpg'];  

        if (window.innerWidth < 1010) {
            // console.log(window.innerWidth);
            this.hideLargeUp = true;
            this.hideMediumDown = false;
        } else {
            // console.log(window.innerWidth + '---');
            this.hideLargeUp = false;
            this.hideMediumDown = true;
        }
        this.itItems = [];
       /* this.carouselOne = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            slide: 1,
            speed: 400,
            interval: 2000,
            point: {
                visible: true
            },
            load: 2,
            touch: true,
            loop: true,
            custom: 'banner'
        };*/
        /*this.config = {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            coverflowEffect: {
                rotate: -8,
                stretch: 0,
                depth: 120,
                modifier: 1,
                slideShadows: false,
            },
            pagination: {
                el: '.swiper-pagination',
            },

            loop: true,
            speed: 950,
        };*/

      /*  this.http.get('/assets/json/parent.json')
            .map(data => data.json())
            .subscribe(data => {
                this.basePath = data.base_path;

                this.http.get(this.basePath + 'Aboutus/About.json')
                    .map(aboutdata => aboutdata.json())
                    .subscribe(aboutdata => {
                        this.abouttitle = aboutdata.title;
                        this.about = aboutdata.about;
                    });

                this.http.get(this.basePath + 'Service/Service.json')
                    .map(servicedata => servicedata.json())
                    .subscribe(servicedata => {
                        this.servicetitle = servicedata.title;
                        this.tabValue1 = servicedata.header[0].h1;
                        this.tabValue2 = servicedata.header[1].h2;
                        this.itItems = servicedata.items.h1.header;
                        this.patchKeys(this.itItems, 'it');
                        this.itesItems = servicedata.items.h2.header;
                        this.patchKeys(this.itesItems, 'ites');
                        this.tempitesbrief = servicedata.items.h2.items;
                        this.subitemvoice = servicedata.items.h2.extras.h1;
                        this.subitemnonvoice = servicedata.items.h2.extras.h2;
                        this.tempitbrief = servicedata.items.h1.items;
                        this.getMargin(0);
                    });
            });*/

        this.mw_tab_e1 = true;
        // this.createParticles();
    }

    // getRoomsList(){
    //     this.roomType = [];
    //     this.roomService.getRoomType().subscribe( response => {
    //       this.roomType = response['data'];
    //     })
    //   }
      
      getBranchList(){
        this.branchList = [];
        this.roomService.getBranches().subscribe( response => {
          this.branchList = response['data'];
          console.log(this.branchList);
        })
      }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    goToITDetail() {
        this.showServices = true;

    }
    gotoContactpage() {
        this.router.navigate(['/contact']).then(() => { window.scrollTo(0, 0); });
    }

    gotoFeedbackPage() {
        this.router.navigate(['/feedback']).then(() => { window.scrollTo(0, 0); });
    }
    gotoSearchingPage() {
        this.router.navigate(['/searching']).then(() => { window.scrollTo(0, 0); });
    }
    gotoChatbotPage() {
        this.router.navigate(['/chatbot']).then(() => { window.scrollTo(0, 0); });
    }
    

    gotoRoomsPage(){
        this.router.navigate(['/rooms']).then(() => { window.scrollTo(0, 0); });
    }
    gotoLoginPage(){ 
        this.router.navigate(['/login']).then(() => { window.scrollTo(0, 0); });
    }
    
    goToITDetailKey() {
        switch (this.key) {
            case 'h1':
                this.router.navigate(['/inhouse']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h2':
                this.router.navigate(['/services']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h3':
                this.router.navigate(['/seo']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h4':
                this.router.navigate(['/web']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h5':
                this.router.navigate(['/bigdata']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h6':
                // this.router.navigate(['/bigdata']).then(() => { window.scrollTo(0, 0); });
                break;
            case 'h7':
                // this.router.navigate(['/bigdata']).then(() => { window.scrollTo(0, 0); });
                break;
            default:
                this.router.navigate(['/']).then(() => { window.scrollTo(0, 0); });
                break;


        }
    }
    goToITESDetail() {
        this.showServices = false;
    }
    getMargin(i) {
        let mykey = 'h' + (i + 1);
        this.mykeybrief = this.tempitbrief[mykey];
        this.key = mykey;
        switch (i) {
            case 0:
                this.position = -2;
                break;
            case 1:
                this.position = -3;
                break;
            case 2:
                this.position = 6;
                break;
            case 3:
                this.position = 15;
                break;
            case 4:
                this.position = 22;
                break;
            case 5:
                this.position = 33;
                break;
            case 6:
                this.position = 41;
                break;
            default:
                this.position = i * 2;
                break;

        }
    }
    patchKeys(data, title) {
        let temparr: any[] = [];
        for (let i = 1; i <= data.length; i++) {
            let name = 'h' + (i);
            temparr.push(name);
        }

        switch (title) {
            case 'it':
                this.itkeys = temparr;
                break;
            case 'ites':
                this.iteskeys = temparr;
                break;
            default:
                this.keys = temparr;
                break;
        }

    }
    getMarginBpo(i) {
        if (i === 0) {
            this.position = -4;
        } else if (i === 1) {
            this.position = 6;
        } else {
            this.position = (i * 5) + 3;
        }
    }
    getPortfolioImg() {
        this.mw_tab_e1 = true;
        this.mw_tab_e2 = false;
        this.mw_tab_e3 = false;
    }
    getPortfolioImg2() {
        this.mw_tab_e1 = false;
        this.mw_tab_e2 = true;
        this.mw_tab_e3 = false;
    }
    getPortfolioImg3() {
        this.mw_tab_e3 = true;
        this.mw_tab_e1 = false;
        this.mw_tab_e2 = false;
    }
   /* createParticles() {
        let particlesData = [];
        document.addEventListener("DOMContentLoaded", function (event) {
            // console.log(window.innerWidth + '---' + navigator.userAgent);
            let isMobile = navigator.userAgent &&
                navigator.userAgent.toLowerCase().indexOf('mobile') >= 0;
            let isSmall = window.innerWidth < 1000;
            let opt = {
                sliderId: 'particle-slider',
                ptlGap: isMobile || isSmall ? 0 : 0,
                ptlSize: isMobile || isSmall ? 1 : 1,
                width: isMobile || isSmall ? 1000 : 1e9,
                height: isMobile || isSmall ? 1000 : 1e9,
                showArrowControls: false,
            };
            particlesData[0] = new ParticleSlider(opt);
        });
    }*/

    onSubmitCheckAvailability(model: FormGroup){
        // console.log(model.value);
        let availValues = model.value;
        availValues.checkIn = moment(availValues.checkIn).local().format("YYYY-MM-DD");                            
        console.log(availValues);
        this.data.setAvailData(availValues);
        
        this.router.navigate(['/rooms']).then(() => { window.scrollTo(0, 0); });
        
    }
}
