import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-design',
  templateUrl: './new-design.component.html',
  styleUrls: ['./new-design.component.scss']
})
export class NewDesignComponent implements OnInit {
  public groupArr:any;
  public data : any = [
    {
      floor:'1',
      room_type : 'Single Bed Room',
      room_no : '11',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Single Bed Room',
      room_no : '12',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Double Bed Room',
      room_no : '13',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Double Bed Room',
      room_no : '14',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Single Bed Room',
      room_no : '21',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Single Bed Room',
      room_no : '22',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Double Bed Room',
      room_no : '23',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Double Bed Room',
      room_no : '24',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Single Bed Room',
      room_no : '31',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Single Bed Room',
      room_no : '32',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Double Bed Room',
      room_no : '33',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Double Bed Room',
      room_no : '34',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Single Bed Room',
      room_no : '41',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Single Bed Room',
      room_no : '42',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Double Bed Room',
      room_no : '43',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Double Bed Room',
      room_no : '44',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    

  ]
  constructor() { }

  ngOnInit(): void {
   console.log(this.data);
   this.groupArr = this.data.reduce((r,{floor})=>{
    if(!r.some(o=>o.floor==floor)){
      r.push({floor,groupItem:this.data.filter(v=>v.floor==floor)});
    }
    return r;
    },[]);
console.log(this.groupArr);
  }

}
