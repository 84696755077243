import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageRoomsService } from '../../services/managerooms.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.scss']
})
export class ManageRoomsComponent implements OnInit {
  createBooking:FormGroup;
  updateBooking:FormGroup;
  deleteRoom:FormGroup;
  roomsList:any[];
  statusList: any [] = [];
  branchList: any [] = [];
  roomTypeList: any [] = [];
  sigdisplay = 'none';
  sigdisplay2 = 'none';
  sigdisplay3 = 'none';
  constructor(private createService:ManageRoomsService,
     private formBuilder:FormBuilder, private spinner:NgxSpinnerService,) { }

  ngOnInit(): void {
     this.createBooking = this.formBuilder.group({
      imageLink2:['',[Validators.required]],
      roomNo:['',[Validators.required]],
      Status:['', [Validators.required]],
      roomType:['',[Validators.required]],
      Branch:['',[Validators.required]],
      Amount:['',[Validators.required]],
      floorNo:['',[Validators.required]],
     
     });
     
     this.updateBooking = this.formBuilder.group({
       imageLink1:['',[Validators.required]],
       imageLink2:['',[Validators.required]],
       imageLink3:['',[Validators.required]],
       imageLink4:['',[Validators.required]],
       imageLink5:['',[Validators.required]],
      roomNo1:['',[Validators.required]],
      Status1:['', [Validators.required]],
      roomType1:['',[Validators.required]],
      Branch1:['',[Validators.required]],
      Amount1:['',[Validators.required]],
      floorNo1:['',[Validators.required]],
      AirConditioning: ['',[Validators.required]],
      room_service: ['',[Validators.required]],
      wifi: ['',[Validators.required]],
      parking2: ['',[Validators.required]],
      parking4: ['',[Validators.required]],
      gym: ['',[Validators.required]],
      restaurant: ['',[Validators.required]],
      adults: ['',[Validators.required]],
      checkin: ['',[Validators.required]],
      checkout: ['',[Validators.required]],
      bed_type: ['',[Validators.required]],
      price: ['',[Validators.required]],


     })

     this.deleteRoom = this.formBuilder.group({
       roomNo2:['', [Validators.required]]
     })

    
     this.getRoomsList();
     this.getRoomStatus();
     this.getBranchList();
     this.getRoomType();
  }

  getRoomStatus(){
    this.createService.getStatus().subscribe(res =>{
      // console.log(res);
      this.statusList = res['data'];
    })
  }

  getBranchList(){
    this.createService.getBranches().subscribe(res => {
      console.log(res);
      this.branchList = res['data'];
    })
  }

  getRoomType(){
    this.roomTypeList = [
      {
       room_type : 'single',
      },
      {
        room_type:'double',
      }
      
    ]
    console.log(this.roomTypeList);
    // this.createService.getRoomType().subscribe(res => {
    //   console.log(res);
    //   this.roomTypeList = res['data'];
    // })
  }

  showAddModal() {
    this.sigdisplay = 'block';
  }

  hideAddModal(): void {
    this.sigdisplay = 'none';
  }

  showUpdateModal(){
    this.sigdisplay2 = 'block';
  }

  hideUpdateModal(){
    this.sigdisplay2 = 'none';
  }

  showDeleteModal(){
    this.sigdisplay3 = 'block';
  }

  hideDeleteModal(){
    this.sigdisplay3 = 'none';
  }


  deleteEventOnRow(num){
    // this.DeleteTitle = num;
    // console.log(this.DeleteTitle);
    console.log(num);
    this.deleteRoom.controls['roomNo2'].patchValue(num);
    this.showDeleteModal();
  }


  getDataPatch(num){
    console.log(num);
    this.showUpdateModal();
    this.updateBooking.controls['roomNo1'].patchValue(num['room_no']);
    this.updateBooking.controls['Status1'].patchValue(num['status']);
    this.updateBooking.controls['roomType1'].patchValue(num['room_type']);
    this.updateBooking.controls['Amount1'].patchValue(num['amount']);
    this.updateBooking.controls['Branch1'].patchValue(num['branch']);
    this.updateBooking.controls['floorNo1'].patchValue(num['floor_no']);
    this.updateBooking.controls['imageLink1'].patchValue(num['image_link']);
    this.updateBooking.controls['imageLink2'].patchValue(num['image_link1']);
    this.updateBooking.controls['imageLink3'].patchValue(num['image_link2']);
    this.updateBooking.controls['imageLink4'].patchValue(num['image_link3']);
    this.updateBooking.controls['imageLink5'].patchValue(num['image_link4']);
    this.updateBooking.controls['AirConditioning'].patchValue(num['air_conditioning']);
    this.updateBooking.controls['room_service'].patchValue(num['room_service']);
    this.updateBooking.controls['wifi'].patchValue(num['free_wifi']);
    this.updateBooking.controls['parking2'].patchValue(num['parkingspacefor2whelers']);
    this.updateBooking.controls['parking4'].patchValue(num['parkingspacefor4whelers']);
    this.updateBooking.controls['gym'].patchValue(num['gym']);
    this.updateBooking.controls['restaurant'].patchValue(num['restaurant']);

    this.updateBooking.controls['adults'].patchValue(num['adults']);
    this.updateBooking.controls['checkin'].patchValue(num['chekintime']);

    this.updateBooking.controls['checkout'].patchValue(num['checkouttime']);

    this.updateBooking.controls['bed_type'].patchValue(num['bed_type']);

    this.updateBooking.controls['price'].patchValue(num['price']);

   


  }

  getRoomsList(){
    this.spinner.show();
    this.roomsList = [];
    this.createService.getRooms().subscribe(res => {
      this.roomsList = res['data'];
      // console.log(this.roomsList);
      this.spinner.hide();
    })
   }

  onSubmitCreate(model:FormGroup){
    console.log(model.value);
    this.createService.postCreateBookings(model.value).subscribe( res => {
      console.log(res);
      swal.fire(
        'success!',
        'Room Booked Successfully',
        'success'
        ).then(function(){
          // this.getCreatedEvents();
          location.reload();
        });
        },
        error => {
        swal.fire(
          'Failes!',
          error,
          'error'
        ).then(function(){
          location.reload();
        }); 
      });
  }

  onSubmitUpdate(model:FormGroup){
    this.createService.postUpdateBookings(model.value).subscribe( res => {
      console.log(res);
      
      swal.fire(
        'success!',
        'Data Updated Successfully',
        'success'
        ).then(function(){
          // this.getCreatedEvents();
          location.reload();
        });
        },
        error => {
        swal.fire(
          'Failes!',
          error,
          'error'
        ).then(function(){
          location.reload();
        }); 
      });
  }

  onSubmitDelete(model:FormGroup){
    this.createService.postDeleteRoom(model.value).subscribe( res => {
      console.log(res);
      swal.fire(
        'success!',
        'Room Deleted Successfully',
        'success'
        ).then(function(){
          // this.getCreatedEvents();
          location.reload();
        });
        },
        error => {
        swal.fire(
          'Failes!',
          error,
          'error'
        ).then(function(){
          location.reload();
        }); 
      });
  }
}
