import { Component, OnInit } from '@angular/core';
import { ManageRoomsService } from '../../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import swal from 'sweetalert2';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    dailyreports:any=[];
    public filterData: any = [];
    data: any;
    filter;

    filterTerm: "";
    filterCode:any;
    component = "component1";

    // @ViewChild('UpdateEvents',null) UpdateEvents: NgForm;
  // 
//   filterTerm: "";
//   filterCode:any;
  p: number = 1;
  bookedRooms:any=[];
  updateData;
  reviewData;
  deleteForm;
  bookingId = '';
  sigdisplay = 'none';
  sigdisplayedit = 'none';
  sigdisplaydelete = 'none';
//   public filterData: any = [];
  public bookingName;
  public gender;
  public mobile;
  public address;
  public checkIn;
  public checkOut;
  public rent;
  public roomNo;
  public branch;
  public roomType;
  public aadhar;
  public personImage;
  public Name;
  public Persons;
       
  public Adharnumber;
  public Amount;
       public Payment_method;
       public date;
  // public startDate = new FormControl(new Date());
    

  constructor(private roomService:ManageRoomsService,
    private formBuilder:FormBuilder, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.getdailyreports();
    this.filter = this.formBuilder.group({
        fromDate:[''],
        toDate:[''],
        branch:['']
    //   checkDate:['']
    })

    this.updateData = this.formBuilder.group({
        checkout_status:[''],
        Booking_id:[''],
        Amount:[''],
        Payment_method:[''],
        Room_no:[''],
        Name:['']
       
    //   checkDate:['']
    })

    this.reviewData = this.formBuilder.group({
        Booking_id:[this.bookingId],
        Person_name1:[''],
        Person_name2:[''],
        mobile:[''],
        Adhar_number1:[''],
        Adhar_number2:[''],
        Branch:[''],
        Amount:[''],
        Payment_method:[''],
       
    })

    this.deleteForm= this.formBuilder.group({
      id:[''],
    })
  }

  getdailyreports(){
    this.spinner.show();
    this.dailyreports = [];
    this.roomService.getdailyreports().subscribe(response => {
    console.log(response,'table data')
      this.dailyreports = response['data'];
      this.filterData = response['data'];
      console.log(this.dailyreports);
      this.spinner.hide();
    })
  }

  filterForm(model:FormGroup){
    this.spinner.show();
    this.roomService.postfilter(model.value).subscribe(response => {
      this.spinner.hide();
      console.log(response);
      this.dailyreports = response['data'];
    //   swal.fire(
    //     response.message,
    //     'success'
    //   ).then(function(){
    //     location.reload();
    //   });
    //   location.reload();
    },
    error => {
      this.spinner.hide();
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 

    })
  }


//   applyFilter(event:string){
//     console.log( event )
//         localStorage.setItem("event", event);
    
//     let filterValueLower = event
//   if(event === "null" ) {
   
//       this.data = this.filterData
   
//      } else if(event === "Hyderabad" ) {
     
//       this.data  = this.filterData.filter((id) => id.Branch === 'Hyderabad')
//      } else if(event === "Tenali" ) {
//         this.data  = this.filterData.filter((id) => id.Branch === 'Tenali') ;
//      } else {
//       this.data  = this.filterData.filter((id) => id.Branch === event) ;
//      }
     
// }

printComponent(cmpName) {
    console.log(cmpName);
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}


openViewModal(e){
    this.sigdisplay = 'block';
    console.log(e);
    this.date = e.date;
    this.bookingId = e.Booking_id;
    this.roomNo = e.RoomNumber;
    this.checkOut = e.Checkout;
    this.Persons=e.Persons;
    // this.gender = e.gender;
    // this.mobile = e.mobile;
    // this.address = e.address;
    // this.checkIn = e.checkindate;
    // this.rent = e.rent;
    // this.branch = e.branch;
    // this.roomType = e.room_type;
    this.reviewData.controls['Person_name1'].patchValue(e['Person_name1']);
    this.reviewData.controls['Person_name2'].patchValue(e['Person_name2']);
    this.reviewData.controls['mobile'].patchValue(e['mobile']);
    this.reviewData.controls['Branch'].patchValue(e['Branch']);
    this.reviewData.controls['Adhar_number1'].patchValue(e['Adhar_number1']);
    this.reviewData.controls['Adhar_number2'].patchValue(e['Adhar_number2']);
    this.reviewData.controls['Amount'].patchValue(e['Amount']);
    this.reviewData.controls['Payment_method'].patchValue(e['Payment_method']);
    
}

  
  hideViewModal(): void {
    this.sigdisplay = 'none';
  }

  openEditModal(e){
    this.sigdisplayedit = 'block';
    this.bookingId = e.Booking_id;
    console.log(e);
  }

  openDeleteModal(e){
    this.sigdisplaydelete = 'block';

    this.deleteForm.controls['id'].patchValue(e['id']);
    console.log(e);
  }

  hideEditModal(): void{
    this.sigdisplayedit = 'none';
  }
  hideDeleteModal(): void{
    this.sigdisplaydelete = 'none';
  }
  openViewaadhar(event) {
    window.open(event, "_blank");
  }
  openViewimg(event) {
    window.open(event, "_blank");
  }
  openViewproof(event) {
    window.open(event, "_blank");
  }
  openViewSignature(event) {
    window.open(event, "_blank");
  }
  aadharView(){
    window.open(this.aadhar, "_blank");
  }  

  imageView(){
    window.open(this.personImage, "_blank");
  }


    downloadPdf(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    downloadPdf2(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    downloadPdf3(pdfUrl, pdfName: string ) {
        // var pdfUrl = Url;
        console.log(pdfUrl);
        //const pdfName = 'your_pdf_file';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    downloadPdf4(pdfUrl, pdfName: string ) {
      // var pdfUrl = Url;
      console.log(pdfUrl);
      //const pdfName = 'your_pdf_file';
      FileSaver.saveAs(pdfUrl, pdfName);
  }


  updateForm(model:FormGroup){
    this.spinner.show();
    this.roomService.postCheckOutDate(model.value, this.bookingId).subscribe(response => {
      this.spinner.hide();
      console.log(response);
      swal.fire(
        response.message,
        'success'
      ).then(function(){
        location.reload();
      });
    },
    error => {
      this.spinner.hide();
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 

    })
  }

  reviewForm(model:FormGroup){
    this.spinner.show();
    this.roomService.postReview(model.value, this.bookingId).subscribe(response => {
      this.spinner.hide();
      console.log(response);
      swal.fire(
        response.message,
        'success'
      ).then(function(){
        location.reload();
      });
    },
    error => {
      this.spinner.hide();
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 

    })
  }

  deleteData(model:FormGroup){
    this.spinner.show();
    this.roomService.deleteData(model.value).subscribe(response => {
      this.spinner.hide();
      console.log(response);
      swal.fire(
        response.message,
        'success'
      ).then(function(){
        location.reload();
      });
    },
    error => {
      this.spinner.hide();
      swal.fire(
        'Failes!',
         error,
        'error'
      ).then(function(){
        location.reload();
      }); 

    })
  }


  applyFilter(event:string){

    // debugger;

        let filterValueLower = event.toLowerCase();
         if(event === '' ) {
             this.bookedRooms=this.filterData;
         } 
         else {
           this.bookedRooms = this.bookedRooms.filter((item) => 
                // item.Booking_id.includes(filterValueLower) || 
                item.mobile.includes(filterValueLower)); 
                // item.Checkout.includes(filterValueLower) || 
                // item.Amount.includes(filterValueLower) ||
                // item.Payment_method.includes(filterValueLower));
        //    item.Checkout.includes(filterValueLower))
         }


        //  let filterValueLower = filterValue.toLowerCase();
        //  if(filterValue === '' ) {
        //      this.employees=this.allEmployees;
        //  } 
        //  else {
        //    this.employees = this.allEmployees.filter((employee) => employee.name.includes(filterValueLower)
        //  }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
