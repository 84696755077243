<div class="container">
    <ngx-spinner 
  bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
  [fullScreen] = "true"><p style="color: white" > Loading... </p>
  </ngx-spinner>
    <div class="card mt-3 shadow">
      <div class="card-header">
        <h4>Feedback</h4>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              
              <th>Email Id</th>
              <th>Mobile</th>
              <th>Message</th>
              <th>Review</th>
              <th>Ever Visited</th>
              
              
            </tr>
          </thead>
          <tbody *ngFor="let item of feedbackList | paginate: { itemsPerPage: 5, currentPage: p }">
            <tr>
              <td>{{item.email_ID}}</td>
              <td>{{item.Mobile_No}}</td>
              <td>{{item.message}}</td>
              <td>{{item.Review}}</td>
              
              <td>{{item.Ever_visited}}</td>
            </tr>
          </tbody>
        </table>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>
  
  
  