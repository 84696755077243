<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>V guestIn - Hotel Booking</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">



<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl" crossorigin="anonymous">


  <script src="https://kit.fontawesome.com/a076d05399.js"></script>
  <style>
      #click {
          display: none;
      }
   label {
       position: absolute;
       right: 30px;
       bottom: 20px;
       height: 55px;
       width: 55px;
       background: -webkit-linear-gradient(left, #a445b2, #fa4299);
       text-align: center;
       line-height: 55px;
       border-radius: 50px;
       font-size: 30px;
       color: #fff;
       cursor: pointer;
   }

   label i{
       position: absolute;
       top: 50%;
       left: 50%;
       transform:  translate(-50%, -50%);
       transition: all 0.4s ease;
   }

   label i.fas{
   opacity: 0;
   pointer-events: none;
   }
   
   #click:checked ~ label i.fas{
    opacity: 1;
   pointer-events: auto;
   transform:  translate(-50%, -50%) rotate(180deg);
   }
   #click:checked ~ label i.fab{
    opacity: 0;
   pointer-events: none;
   transform:  translate(-50%, -50%) rotate(180deg);
   }
.wrapper{
  position: absolute;
      right: 30px;
      bottom: 0px;
      max-width: 4000px;
      background: #fff;
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}

#click:checked ~ .wrapper {
    opacity: 1;
    bottom: 70px;
    pointer-events: auto;
}
.wrapper .head-text{
line-height: 60px;
color: #fff;
border-radius: 15px 0 0;
padding: 0 20px;
font-weight: 500;
font-size: 20px;
background: -webkit-linear-gradient(left, #a445b2, #fa4299);
}
.wrapper .chat-box{
 padding: 50px;
 width: 70%;
}
.chat-box .desc-text{
color: #515365;
text-align: center;
line-height: 25px;
font-size: 17px;
font-weight: 500;

}

.chat-box form{
padding: 10px 15px;
margin: 20px 0;
border-radius: 25px;
border: 1px solid lightgray;

}
.chat-box form .field{
height: 50px;
width: 100%;
margin-top: 20px;
}

.chat-box form .field:last-child{
margin-bottom: 15px;
}


form .field input,
form .field button {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  border: 1px ssolid lightgray;
  outline: none;
  border-radius: 25px;
  font-size: 16px;
}
form .field input:focus,
form .field button:focus {
    border-color: #fc83bb;
}

form .field input::placeholder{
    color: silver;
} 
form .field input:focus::placeholder,
form .field button:focus::placeholder{
    color: lightgrey;
} 

.chat-box form .field button {
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease;
    background: -webkit-linear-gradient(left, #a445b2, #fa4299);
}

.chat-box form .field button:active {
    transform: scale(0.97);
}
iframe.hidden
{
display:none
}

#cntr {
    width: 1250px;
    display: block;
    position: relative;
    top: 0;
    float: none;
    margin: 0 auto;
    background-color: white;
    height:100%;
    }
  </style>
</head>



<body  data-spy="scroll">
  
<div class="container"  style="overflow-y: scroll;" >
  <input type="checkbox" id="click">
  <label for="click">
  <i class="fab fa-facebook-messenger"></i>
  <i class="fas fa-times"></i>
  </label>
  <!-- //chatbot -->
  
  <div class="wrapper" style="overflow-y: scroll;">
    <div class="head-text" style="overflow-y: scroll;">
      V Guist Inn
    </div>
    <div class="chat-box">
      <iframe width="0" height="0" tabindex="-1" name="hiddenFrame" class="hide"></iframe>
      <!-- <div id="popcode"></div> -->
      <div class="txt">
          <div class="desc-text">Please fill out the form before start chating</div>
  
          <!-- action="/info" method="POST" -->
          <form target="hiddenFrame">
            <div class="field">
              <input type="text" placeholder="Your First Name" id="firstname" required>
        
            </div>
            <div class="field">
              <input type="text" placeholder="Your Lasst Name" id="lastname" required>
              
            </div>
            <div class="field">
              <input type="text" placeholder="Your Email" id="email" required>
              
            </div>
            <div class="field">
           <button type="submit" id="callback" onClick="myFunctionfirt()">Start Chat</button>
              
            </div>
          </form>
      </div>
      
  
    <div class="frame">
    
      <div class="chatOptions">
          <div class="desc-text">Kindly provide your query.</div>
  
          <div style="width: 500px;" id="popcode"></div>
          
        
          <div class="msj-rta macro" style="margin:auto">                        
              <div class="text text-r" style="background:whitesmoke !important;">
                  <form target="hiddenFrame">
                   
  
                    <div class="input-group">
                      <input type="text" class="form-control" id="chat_box" name="user"  placeholder="Type your query..." aria-label="Recipient's username" aria-describedby="button-addon2">
                      <button class="btn btn-outline-success" type="button" id="button-addon2" onClick="myFunction()">Submit</button>
                    </div>

             <!-- <input class="mytext" placeholder="Type a message" id="chat_box" name="user" style="width: 500px;" autofocus="true" /> -->
             
              <!-- <button class="btn btn-success" type="submit" onClick="myFunction()">Start Chat</button> -->
          </form>
              </div> 
          </div>
      </div>
  </div>  
  
    </div>
  </div>
</div>
<!-- chatbot?? -->

 
<!-- <input type="text" name="text-574" value="" size="40" class="txt" placeholder="Name">
<input type="text" name="text-574" value="" size="40" class="txt" placeholder="Telephone">
        <button type="button" id="callback" >
Call Back
</button> -->
<script>


function myFunctionfirt() {
  var firstname = $('#firstname').val()
  var lastname = $('#lastname').val()
  var email = $('#email').val()

  $.post("http://localhost:9000/info",
    { user: firstname,
      luser: lastname,
     lemail: email },
    function(data,status){
     console.log(data)
     $('#popcode').append('<div class="pt-3" style="font-weight: bold;">' + "BOT :  " + data.data + '</div>');
    //  document.getElementById("popcode2").innerHTML =data.data;
    });

// console.log(data)
// $('#popcode').append('<div>'  + "USER :  " +  data + '</div>');
// document.getElementById("popcode").innerHTML = `<p> $data </p>`;

}


jQuery( document ).ready(function() {

jQuery(".txt").show();
jQuery(".chatOptions").hide();
   jQuery("#callback").click(function(){
   jQuery(".txt").hide();
   jQuery("#callback").hide();
   jQuery(".chatOptions").show();

   });
});

function myFunction() {
  var data = $('#chat_box').val()

  if(data.startsWith("BOOKINGID=")) {
    $.post("http://localhost:9000/users",
    { user: data},
    function(data,status){
      console.log(data.data.length)
      if(data.data.length === 0) {
        $('#popcode').append('<div class="text-danger pt-3">' + "BOT :  " + "Kindly provide correct booking id. In this format BOOKINGID=your id will go here (BOOKINGID=XXXXXX) " + '</div>');
      }
          else {    console.log(data.data)
     $('#popcode').append(`<div class="text-primary"> Booking Details: <li>Booking ID: ${data.data[0].booking_id} </li> 
                                          <li> Booking Name: ${data.data[0].booking_name}</li> 
                                          <li> Check-in Date: ${data.data[0].checkindate}</li>
                                          <li> Check-out Date: ${data.data[0].checkoutdate}</li> 
                                          <li> Room type: ${data.data[0].room_type}</li>
                                          <li> Total Rooms: ${data.data[0].rooms}</li> 
                                          <li> Contact No: ${data.data[0].mobile}</li>
                                          </div>`);}
    //  document.getElementById("popcode2").innerHTML =data.data;
    });
  }

else {
    $.post("http://localhost:9000/users",
    { user: data},
    function(data,status){
     console.log(data)
     $('#popcode').append('<div class="text-primary">' + "BOT :  " + data.data + '</div>');
    //  document.getElementById("popcode2").innerHTML =data.data;
    });

console.log(data)
$('#popcode').append('<div class="pt-2 pb-2 text-success" >'  + "USER :  " +  data + '</div>');
// document.getElementById("popcode").innerHTML = `<p> $data </p>`;

}

}
</script>

 
</body>
</html>


