import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteService } from '../services/site.service';
import { ManageRoomsService } from '../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { DataService } from "../services/data.service";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-img-info',
  templateUrl: './img-info.component.html',
  styleUrls: ['./img-info.component.scss']
})

  export class ImgInfoComponent implements OnInit {

    public groupArr:any;
    public data;
    public newData:any
    headers= new HttpHeaders({ 
      'Content-Type': 'application/json; charset=utf-8'
    });
    options = { headers: this.headers };

    constructor(
      private router:ActivatedRoute, 
      private _fa: FormBuilder, 
      private siteService: SiteService, 
      private dataService: DataService,
      private roomService:ManageRoomsService, 
      private spinner:NgxSpinnerService,
      private http:HttpClient) { }

      room_no: any;
     
    ngOnInit(): void {
      this.data = [];
      this.siteService.apiRoomView(this.router.snapshot.params.room_no).subscribe((res)=>{
        console.log(res.data);
     this.data.push(res.data[0])
  //  return newData;
    console.log(this.data)
   let x = this.data
   let zz = "asdfasfaf"
      })
    }
// console.log(data)

  }
 