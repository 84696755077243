<div class="container">
    <ngx-spinner 
  bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" 
  [fullScreen] = "true"><p style="color: white" > Loading... </p>
  </ngx-spinner>
    <div class="card mt-3 shadow">
      <div class="card-header">
          <div class="row">
                <div class="col-md-6">
                    <h3>Daily Report</h3>
                </div>
                <div class="col-md-6">
                    <div class="float-right">
                      <button type="button" class="btn btn-primary" (click)="printComponent(component)">
                        <span class="glyphicon glyphicon-print" aria-hidden="true"></span>Print
                      </button>

                      <!-- <button mat-raised-button type="submit" onclick="window.print();" 
                class="btn btn-success float-right">Print/Submit</button></a> -->
                    </div>
                </div>
            </div>
      </div>
      <div class="card-body">
        <!-- <p>
            <input type="text"  class="form-control" [(ngModel)]="filterTerm" placeholder="Search by Booking Id" >
      </p> -->
          <form [formGroup]="filter" (ngSubmit)="filterForm(filter)">
          <div class="row" style="margin-bottom: 16px;">
                <div class="col">
                    <label><b>Filter by From Date</b><span class="text-danger">*</span></label>
                    <input type="date" class="form-control" placeholder="Date" formControlName="fromDate">
                    
                </div>
                <div class="col">
                    <label><b>Filter by To Date</b><span class="text-danger">*</span></label>
                    
                    <input type="date" class="form-control" placeholder="To Date" formControlName="toDate">
                </div>
                <div class="col">
                    <label><b>Filter by Branch</b><span class="text-danger">*</span></label>
                    <select class="form-control" (click)="applyFilter($event.target.value)" formControlName="branch">
                        <!-- <option>Select any one Branch</option> -->
                        <option value="null">All</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Tenali">Tenali</option>
                    </select>

                </div>
                <div class="col" style="align-self: flex-end">
                    <button type="submit" class="btn btn-success">Search</button>
                </div>
            </div>
        </form>
            <!-- <input type="text"  class="form-control" [(ngModel)]="filterTerm" placeholder="Search..." > -->
            <!-- (keyup)="applyFilter($event.target.value)" -->
            <!-- <input type="text" class="form-control"  placeholder="Enter Mobile for Search" 
                          [(ngModel)]="filterCode" (keypress)="numberOnly($event)"> -->
        <!-- <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterTerm"> -->
        <div class="print-container" id="component1">
        <table class="table table-bordered table-responsive">
          <thead class="thead-dark">
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Booking Id</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Aadhar No</th>
                <th scope="col">Persons</th>
                <!-- <th scope="col">Address</th> -->
                <th scope="col">Room No</th>
                <th scope="col">Branch</th>
                <!-- <th scope="col">Check In</th> -->
                <th scope="col">Check Out</th>
                <th scope="col">Amount</th>
                <th scope="col">Payment Mode</th>
                <th scope="col">Status</th>
                <th scope="col">Aadhar</th>
                <th scope="col">Person Image</th>
                <th scope="col">Other Proof</th>
                <th scope="col">Signature</th>
                <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of dailyreports | grdFilter:filterTerm; let i=index">
            <tr>
                <td *ngIf="item.date === 'NULL'"></td>
                <td *ngIf="item.date !='NULL' ">{{item.date | date : "dd/MM/y"}}</td>
                <!-- <td >{{item.date}}</td> -->
              <td>{{item.Booking_id}}</td>
              <td *ngIf="item.Persons === null">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 1">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 2">{{item.Person_name1}}, {{item.Person_name2}}</td>
              <td>{{item.mobile}}</td>
              <td *ngIf="item.Persons === null">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 1">{{item.Adhar_number1}}</td>
              <td *ngIf="item.Persons === 2">{{item.Adhar_number1}}, {{item.Adhar_number2}}</td>
              <td>{{item.Persons}}</td>
              <!-- <td>Address dggh fhf</td> -->
              <td>{{item.Room_no}}</td>
              <td>{{item.Branch}}</td>
              <!-- <td>Yes </td> -->
              <td>{{item.Checkout}}</td>
              <td>{{item.Amount}}</td>
              <td>{{item.Payment_method}}</td>
              <td>{{item.Status}}</td>
              <td style='white-space: nowrap'>
                <button class="btn btn-primary" (click)="openViewaadhar(item.aadhar)"><i class="fa fa-eye"></i></button>
                <button class="btn btn-success" (click)="downloadPdf(item.aadhar,'aadhar')" ><i class="fa fa-download"></i></button>
              </td>
              <td style='white-space: nowrap'>
                <button class="btn btn-primary" (click)="openViewimg(item.person_image)"><i class="fa fa-eye"></i></button>
                <button class="btn btn-success" (click)="downloadPdf2(item.person_image,'person_image')" ><i class="fa fa-download"></i></button>
              </td>
              <td style='white-space: nowrap'>
                  <button class="btn btn-primary" (click)="openViewproof(item.Proof)"><i class="fa fa-eye"></i></button>
                  <button class="btn btn-success" (click)="downloadPdf3(item.Proof,'other_proof')" ><i class="fa fa-download"></i></button>
              </td>
              <td style='white-space: nowrap'>
                <button class="btn btn-primary" (click)="openViewSignature(item.Signature)"><i class="fa fa-eye"></i></button>
                <button class="btn btn-success" (click)="downloadPdf4(item.Signature,'Signature')" ><i class="fa fa-download"></i></button>
              </td>
              <td style='white-space: nowrap'>
                <button class="btn btn-primary" (click)="openViewModal(item)"><i class="fa fa-eye"></i></button>
                <button class="btn btn-warning" (click)="openEditModal(item)"><i class="fa fa-pencil-square-o"></i></button>
                <button class="btn btn-danger" (click)="openDeleteModal(item)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
            <!-- <tr>
                <td>25/04/2022</td>
                <td>IU1255</td>
                <td>name</td>
                <td>9603187904</td>
                <td>234567890</td>
                <td>2</td>
                <td>Address dggh fhf</td>
                <td>111</td>
                <td>Tenali</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>2000</td>
                <td>Offline</td>
              </tr> -->
          </tbody>
        </table>
        </div>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <!-- <div>
            <label>Total Amount Paid through online: <span style="color: gray;"> Rs.4000</span></label>
        </div> -->
      </div>
    </div>
  </div>

  <br>
  <br>
  <br>
  <br>


  <div class="backdrop" [ngStyle]="{'display':sigdisplay}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplay}">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><strong>Review Details</strong></h4>
              <button type="button" class="close" (click)="hideViewModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form [formGroup]="reviewData" (ngSubmit)="reviewForm(reviewData)">
          <div class="modal-body">
              <table class="table table-bordered">
                
                <tbody>
                    <tr>
                        <th>Date:</th>
                        <!-- <td>{{date}}</td> -->
                        <td *ngIf="date === 'NULL'"></td>
                        <td *ngIf="date !='NULL' ">{{date | date : "dd/MM/y"}}</td>
                    </tr>
                  <tr>
                    <th>Booking Id:</th>
                    <td>{{bookingId}}</td>
                  </tr>
                  <!-- <td *ngIf="item.Persons === null">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 1">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 2">{{item.Person_name1}}, {{item.Person_name2}}</td>
              <td *ngIf="item.Persons === null">{{item.Person_name1}}</td>
              <td *ngIf="item.Persons === 1">{{item.Adhar_number1}}</td>
              <td *ngIf="item.Persons === 2">{{item.Adhar_number1}}, {{item.Adhar_number2}}</td> -->
                  <tr>
                    <th>Person One Name:</th>
                    <td><input formControlName="Person_name1" class="form-control" ></td>
                  </tr>
                  <tr>
                    <th>Person Two Name:</th>
                    <td><input formControlName="Person_name2" class="form-control" ></td>
                  </tr>
                  <tr>
                    <th>Mobile:</th>
                    <td><input formControlName="mobile" class="form-control" ></td>
                  </tr>
                  <tr>
                    <th>Person One Aadhar No:</th>
                    <td><input formControlName="Adhar_number1" class="form-control" ></td>
                  </tr>
                  <tr>
                    <th>Person One Aadhar No:</th>
                    <td><input formControlName="Adhar_number2" class="form-control" ></td>
                  </tr>
                  <!-- <tr>
                    <th>Persons:</th>
                    <td>{{rent}}</td>
                  </tr> -->
                 
                  <tr>
                    <th>Persons:</th>
                    <td>{{Persons}}</td>
                  </tr>
                  <tr>
                    <th>Branch:</th>
                    <td> 
                        <select formControlName="Branch" class="form-control">
                            <option value="Hyderabad">Hyderabad</option>
                            <option value="Tenali">Tenali</option>
                        </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Check-out:</th>
                    <td>{{checkOut}}</td>
                  </tr>
                  <tr>
                    <th>Amount:</th>
                    <td><input formControlName="Amount" class="form-control"></td>
                  </tr>
                  <tr>
                    <th>Payment Mode:</th>
                    <td>
                        <select formControlName="Payment_method" class="form-control">
                            <option value="Cash">Cash</option>
                            <option value="Online">Online</option>
                        </select>
                    </td>
                  </tr>
                  <!-- <tr>
                    <th>Aadhar:</th>
                    <td><button class="btn btn-success" (click)="aadharView()">View</button></td>
                  </tr>
                  <tr>
                    <th>Person Image:</th>
                    <td><button class="btn btn-success" (click)="imageView()">View</button></td>
                  </tr> -->
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
           
              <button type="button" class="btn btn-secondary" (click)="hideViewModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Close</button>
              <button type="submit" class="btn btn-success"><span class="glyphicon glyphicon-send" aria-hidden="true"></span> Verify</button>
      
      
            </div>
          </form>
    
       
        
          </div>
          <!-- <br><br><br> -->
      </div>
      <!-- <br><br><br> -->
  </div> 
  <!-- <br><br><br> -->
  
  <!--END START Modal -->


  <div class="backdrop" [ngStyle]="{'display':sigdisplayedit}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplayedit}">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content pt-3 pb-3">
        <div class="modal-header">
          <h4 class="modal-title"><strong>Update</strong></h4>
              <button type="button" class="close" (click)="hideEditModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form [formGroup]="updateData" (ngSubmit)="updateForm(updateData)">

          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Name:</label>
            <div class="col-sm-5">
              <input formControlName="Name" class="form-control">
              <!-- <owl-date-time #dtPicker4></owl-date-time> -->
            </div>

          </div>

          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Room No:</label>
            <div class="col-sm-5">
              <input formControlName="Room_no" class="form-control">
              <!-- <owl-date-time #dtPicker4></owl-date-time> -->
            </div>

          </div>

          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Checkout Status:</label>
            <div class="col-sm-5">
              <!-- <input formControlName="Booking_id" class="form-control" hidden> -->
              <select formControlName="checkout_status" class="form-control">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
              </select>
              <!-- <owl-date-time #dtPicker4></owl-date-time> -->
            </div>

          </div>

          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Amount Rs:</label>
            <div class="col-sm-5">
              <input formControlName="Amount" class="form-control">
              <!-- <owl-date-time #dtPicker4></owl-date-time> -->
            </div>

          </div>

          <div class="form-group row mx-auto mt-3">
            <label for="database" class="col-sm-4 col-form-label">Payment Mode:</label>
            <div class="col-sm-5">
              <!-- <input formControlName="Booking_id" class="form-control" hidden> -->
              <select formControlName="Payment_method" class="form-control">
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                  <option value="Card">Card</option>
              </select>
              <!-- <owl-date-time #dtPicker4></owl-date-time> -->
            </div>

          </div>
          
            

        <button type="submit" class="btn btn-success ml-3">submit</button>
        </form>
    
       
        
          </div>
      </div>
  </div>


  <div class="backdrop" [ngStyle]="{'display':sigdisplaydelete}"></div>
  <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':sigdisplaydelete}">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><strong>Delete Details</strong></h4>
              <button type="button" class="close" (click)="hideDeleteModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
  
        <form [formGroup]="deleteForm" (ngSubmit)="deleteData(deleteForm)">
          <div class="modal-body">
            <input type="hidden" formControlName="id" class="form-control" required>
            Are you sure you want to delete?
            </div>
            <div class="modal-footer">
           
              <button type="button" class="btn btn-secondary" (click)="hideDeleteModal()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> No</button>
              <button type="submit" class="btn btn-primary"><span class="glyphicon glyphicon-send" aria-hidden="true"></span> Yes</button>
      
      
            </div>
          </form>
    
       
        
          </div>
          <!-- <br><br><br> -->
      </div>
      <!-- <br><br><br> -->
  </div> 


