
import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../services/site.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import appConstants from '../config/app.constants';
// import { AuthService } from "../auth.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ModalDirective } from 'ngx-bootstrap/modal';
// import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
// import { UtilService } from '../services/util.service';
import { AuthService } from "../auth.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private siteService: SiteService, 
    private formBuilder: FormBuilder, 
    private router: Router, private http:HttpClient,
    private spinner:NgxSpinnerService,
    private authService: AuthService,
    private toastrService: ToastrService
    ) { }

   loginForm: FormGroup;


  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
     });
  }

  get f() { return this.loginForm.controls; }

  login(){
    this.spinner.show();
    this.siteService.login(this.loginForm.value).subscribe((res) =>{
      // console.log(res, "User Sucessfully Logged in!.");
      console.log(res);
      // sessionStorage.setItem('userdata', JSON.stringify(res['data'][0]));
    if(res.status == true){
      this.alertSuccess();
      this.spinner.hide();
      // sessionStorage.setItem('userdata', JSON.stringify(res['data'][0]));
      this.authService.setLoginDetails(res);
      this.router.navigate(["default"]);
    } else {
      this.spinner.hide();
      this.alertNotSuccess();
    }
       
  
    },
    error => {
      if(error.status === 403){
        this.spinner.hide();
        this.alertNotSuccess();
      }
     
      
     });
  }
  
  alertSuccess() {
    swal.fire(
        'Success',
        'Login Success',
        'success'
    )
}
alertNotSuccess() {
    swal.fire(
        'Not Success',
        'Login Failed',
        'error'
    )
}

  }
  