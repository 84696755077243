import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilter'
//   pure: false
})
export class GrdFilterPipe implements PipeTransform {

    // transform(items: any, filter: any, defaultFilter: boolean): any {
    //     if (!filter){
    //       return items;
    //     }
    
    //     if (!Array.isArray(items)){
    //       return items;
    //     }
    
    //     if (filter && Array.isArray(items)) {
    //       let filterKeys = Object.keys(filter);
    
    //       if (defaultFilter) {
    //         return items.filter(item =>
    //             filterKeys.reduce((x, keyName) =>
    //                 (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
    //       }
    //       else {
    //         return items.filter(item => {
    //           return filterKeys.some((keyName) => {
    //             return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
    //           });
    //         });
    //       }
    //     }
    // }


    transform(value: any, args?: any): any {
        if (!args) {
          return value;
        }
        return value.filter((item) => {
          let rVal = (item.Booking_id.includes(args));
           // item.Booking_id.includes(filterValueLower) || 
           // item.mobile.includes(filterValueLower)); 
           // item.Checkout.includes(filterValueLower) || 
           // item.Amount.includes(filterValueLower) ||
           // item.Payment_method.includes(filterValueLower));
          return rVal;
        })
    
      }

}


// import { Pipe, PipeTransform } from '@angular/core';
// @Pipe({
//   name: 'customerEmailFilter'
// })
// export class SearchPipe implements PipeTransform {

  

// }