// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://vguestinn.accuracy.live/'
    //  apiUrl: 'https://api.vguestinn.com/'
    // apiUrl:'http://localhost:9000/'
        // http://209.105.231.143:9090/
         // apiUrl: 'http://108.166.183.175:4000/'

//live
    apiUrl:'https://api.vguestinn.com/'

    //iheal
    // apiUrl:'http://209.105.231.143:9000/'

   
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
