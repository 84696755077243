<!-- 

<div class="card">
  <div class="row main">
       <div class="card-header">
          <h1 class="text-center ">Change Password </h1>
        </div>
    <div class="card-body">
            
          <div class="input-group ">
            <span class="input-group-addon"><span class="glyphicon glyphicon-envelope" aria-hidden="true"></span></span>
            <input id="email" type="text" class="form-control" name="email" placeholder="Enter your Email">
          </div>
          <div class="input-group">
            <span class="input-group-addon"><span class="glyphicon glyphicon-lock" aria-hidden="true"></span></span>
            <input id="password" type="password" class="form-control" name="password" placeholder="Enter your Password">
          </div>
          <div class="input-group">
            <span class="input-group-addon"><span class="glyphicon glyphicon-lock" aria-hidden="true"></span></span>
            <input id="password" type="password" class="form-control" name="Confirm password" placeholder="Confirm Your Password">
          </div>
      
      </div>
    
</div>
<div class="card-footer">
<div class="form-group ">
  <a type="submit" class="btn btn-danger btn-lg btn-block login-button">Confirm</a>
</div>
</div>
 -->

<div class="container">



  <div class="card mt-3 shadow">
    <div class="card-header">
      <h3>Manage Users</h3>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="my-select" class="col-md-4">Select to Manage Users/change password</label>
        <select id="my-select" class="form-control col-md-4" (change)="onChangeFeature($event.target.value)">
          <option value="0">Select</option>
          <option value="Registration">Registration</option>
          <option value="Change Password">Change Password</option>
        </select>
      </div>


      <div *ngIf="Registration">
        <form [formGroup]="registerUser" (ngSubmit)="onSubmit(registerUser)">
          <!-- {{subscriberProfileForm.value | json}} -->
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="name" class="col-sm-2 col-form-label">Name<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="name" placeholder="Name"/>
                  <div *ngIf="g.name.invalid && (g.name.dirty || g.name.touched)">
                      <p *ngIf="g.name.errors.required" class="text-danger font-weight-bold">
                          <i>Name required</i>
                      </p>
                  </div> 
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="email_user_id" class="col-sm-2 col-form-label">Email<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="email" class="form-control" formControlName="email_id" placeholder="Email" />
                  <div *ngIf="g.email_id.invalid && (g.email_id.dirty || g.email_id.touched)">
                      <p *ngIf="g.email_id.errors.required" class="text-danger font-weight-bold">
                        <i>Email necessary</i>
                      </p>
                      <p *ngIf="g.email_id.errors.email" class="text-danger font-weight-bold">
                        <i>Email must be valid</i>
                      </p>
                  </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="password" class="col-sm-2 col-form-label">Password<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="password" class="form-control" formControlName="password" placeholder="********" />
                  <div *ngIf="g.password.invalid && (g.password.dirty || g.password.touched)">
                      <p *ngIf="g.password.errors.required" class="text-danger font-weight-bold">
                        <i>Password necessary</i>
                      </p>
                  </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="area" class="col-sm-2 col-form-label">Area<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="area" placeholder="Area" />
                  <div *ngIf="g.area.invalid && (g.area.dirty || g.area.touched)">
                      <p *ngIf="g.area.errors.required" class="text-danger font-weight-bold">
                        <i>Area necessary</i>
                      </p>
                  </div>
              </div>
          </div>



          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="city" class="col-sm-2 col-form-label">City<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="city" placeholder="City" />
                  <div *ngIf="g.city.invalid && (g.city.dirty || g.city.touched)">
                      <p *ngIf="g.city.errors.required" class="text-danger font-weight-bold">
                        <i>City necessary</i>
                      </p>
                  </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="state" class="col-sm-2 col-form-label">State<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="state" placeholder="state" />
                  <div *ngIf="g.state.invalid && (g.state.dirty || g.state.touched)">
                      <p *ngIf="g.state.errors.required" class="text-danger font-weight-bold">
                        <i>State necessary</i>
                      </p>
                  </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="country" class="col-sm-2 col-form-label">Country<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="country" placeholder="country" />
                  <div *ngIf="g.country.invalid && (g.country.dirty || g.country.touched)">
                      <p *ngIf="g.country.errors.required" class="text-danger font-weight-bold">
                        <i>Country necessary</i>
                      </p>
                  </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group row">
              <label for="mobile_no" class="col-sm-2 col-form-label">Mobile No<i class="text-danger">*</i></label>
              <div class="col-sm-4 row">
                  <input type="text" class="form-control" formControlName="mobile_no" (keypress)="keyPress($event)" minlength=10 maxlength=10  placeholder="mobile no" />
                  <div *ngIf="g.mobile_no.invalid && (g.mobile_no.dirty || g.mobile_no.touched)">
                      <p *ngIf="g.mobile_no.errors.required" class="text-danger font-weight-bold">
                        <i>Mobile number necessary</i>
                      </p>
                  </div>
              </div>
          </div>
          
          

          <button type="submit" class="btn btn-success" [disabled]="!registerUser.valid">Submit</button>
      </form>
      </div>

      <div *ngIf="ChangePassword">
      <form [formGroup]="forgetPassword" (ngSubmit)="forgetPasswordForm(forgetPassword)">
        <div class="input-group ">
          <span class="input-group-addon"><span style="margin-left: -5px !important;"
              class="glyphicon glyphicon-envelope" aria-hidden="true"></span></span>
          <input id="email" type="text" formControlName="Email5" class="form-control col-md-6" name="email"
            placeholder="Enter your Email" readonly>
        </div>
        <br>
        <div class="input-group">
          <span class="input-group-addon"><span style="margin-left: -5px !important;" class="glyphicon glyphicon-lock"
              aria-hidden="true"></span></span>
          <input id="password" type="password" formControlName="newPassword" class="form-control col-md-6"
            name="newPassword" placeholder="Enter your Password">
          <div *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)">
            <p *ngIf="f.newPassword.errors.required" class="text-danger">
              Password required
            </p>
            <p *ngIf="f.newPassword.errors.minlength" class="text-danger">
              Password must have atleast 8 characters
            </p>
          </div>
        </div>
        <br>
        <div class="input-group">
          <span class="input-group-addon"><span style="margin-left: -5px !important;" class="glyphicon glyphicon-lock"
              aria-hidden="true"></span></span>
          <input id="password" type="password" formControlName="confirmPassword" class="form-control col-md-6"
            name="confirmPassword" placeholder="Confirm Your Password">
          <div *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)">
            <p *ngIf="f.confirmPassword.errors?.required" class="text-danger">
              Password match is necessary
            </p>
            <p *ngIf="f.confirmPassword.errors.mustMatch" class="text-danger">
              Password mismatch
            </p>
          </div>
        </div>
        <br>
        <div class="card-footer text-center">
          <button type="submit" class="btn btn-primary" [disabled]="forgetPassword.invalid">
            Submit
          </button>

        </div>
      </form>
      </div>
    </div>
  </div>
</div>