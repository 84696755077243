<!-- <div class="column">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<div class="column">
    <div class="col-md-12">

        <div class="row">
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4><b>Jane Doe</b></h4>
                        <p>Interior Designer</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div> -->
<!-- <div class="row">


<div class="container">
    <div class="card">
    <div class="row">
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 1</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">14</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 1</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">13</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 2</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">24</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 2</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">23</p>
            </div>
        </div>
      </div>
     
      <div class="w-100"></div>
  
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 1</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">11</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 1</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">12</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 2</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">21</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 2</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">22</p>
            </div>
        </div>
      </div>
    </div>
    </div>
</div>
<div class="container">
    <div class="card">
    <div class="row">
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 3</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">34</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 3</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">33</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 4</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">44</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/double.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Double room</b></h4>
                <p>1500</p>
                <p>FLOOR 4</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">43</p>
            </div>
        </div>
      </div>
      
      <div class="w-100"></div>
  
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 3</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">31</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 3</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">32</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 4</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">41</p>
            </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <div class="card">
            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
            <div class="container">
                <h4><b>Single room</b></h4>
                <p>1000</p>
                <p>FLOOR 4</p>
                <p><button class="btn btn-primary">Book</button></p>
                <p class="float-right text-success" style="font-size: medium;">42</p>
            </div>
        </div>
      </div>
    </div>
    </div>
</div>
</div> -->


<!-- <div class="row">
    <div class="col-6 col-sm-3" *ngFor="let item of groupArr">
<div class="card">
    <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
    <div class="container">
        <p>{{item.floor}}</p>
        <div *ngFor="let value of item.groupItem">
            <h4><b>{{value.product_name}}</b></h4>
            <p>{{value.product_price}}</p>
        </div>
        <!-- <h4><b>Single room</b></h4>
        <p>1000</p>
        <p>FLOOR 4</p>
        <p><button class="btn btn-primary">Book</button></p> -->
        <!-- <p class="float-right text-success" style="font-size: medium;">42</p>
    </div>
</div>
</div> -->
<!-- </div>  -->
-->


<div class="container">
    <div class="card">
        <div class="card-body">
            
            <div *ngFor="let hd of groupArr">
                <div class="card-header bg-secondary">
                    <h4 class="text-center text-white">FLOOR {{hd.floor}}</h4>
                </div>
                <div class="row card-group">

                    <div class="col-3" *ngFor="let value of hd.groupItem">
                        <div class="card">

                            <img src="assets/img/images.jpg" alt="Avatar" style="width:100%">
                            <h4 class=""><b>{{value.room_type}}</b></h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 class="ml-5">Room No<p class="text-success">{{value.room_no}}</p>
                                    </h4>
                                </div>
                                <div class="col-md-6">
                                    <h4 class="ml-5">Price<p class="text-success">{{value.product_price}}</p>
                                    </h4>
                                </div>
                            </div>

                            <button class="btn btn-success float-right mb-2" >Book</button>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>