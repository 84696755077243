import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// @Injectable()
@Injectable({
    providedIn: 'root'
  })
export class DataService {

  
  private profileData = new BehaviorSubject({});
  getProfileData = this.profileData.asObservable();

  private avilData = new BehaviorSubject({});
  getAvailData = this.avilData.asObservable();

  constructor() { }

  setProfileData(message: Object) {
    this.profileData.next(message)
  }

  setAvailData(message: Object){
    this.avilData.next(message);
  }
}