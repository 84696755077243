import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupName } from '@angular/forms';
import {SiteService } from '../services/site.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-searching',
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.scss']
})
export class SearchingComponent implements OnInit {
  searching:FormGroup;
  Cancellation: FormGroup;
  data: any;
  opentable: boolean=false;
  sigdisplay3 = 'none';
  constructor(private fb:FormBuilder, private searchService:SiteService, private spinner:NgxSpinnerService) {

   }

  ngOnInit(): void {
    this.searching = this.fb.group({
      bookingId:['',[Validators.required]],
      mobileNo:['',[Validators.required]]
    })
    this.Cancellation = this.fb.group({
      book_id:['',[Validators.required]],
      
    })
    
  }
  showDeleteModal(){
    this.sigdisplay3 = 'block';
  }

  hideDeleteModal(){
    this.sigdisplay3 = 'none';
  }
  onSearching(model:FormGroup){
    this.spinner.show();
    this.data=[];
    this.searchService.apiGetBookedData(model.value).subscribe(response=>{
      this.spinner.hide();
      this.data = response['data'];
      this.opentable = true;
      console.log(this.data);
    })
  }

  cancelBooking(event){
    console.log(event);
    this.Cancellation.controls['book_id'].patchValue(event);
    this.showDeleteModal();
  }

  onSubmitCancel(model:FormGroup){
    this.spinner.show();
    this.searchService.apiCancelRoom(model.value).subscribe(response=>{
      this.spinner.hide();
      console.log(response);
      swal.fire(
        'success!',
        'Cancellation Request Sent to Admin',
        'success'
        ).then(function(){
          // this.getCreatedEvents();
          location.reload();
        });
        },
        error => {
        swal.fire(
          'Failes!',
          error,
          'error'
        ).then(function(){
          location.reload();
        }); 
      });
  }

}
