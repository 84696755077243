<div class="card ml-2 mr-2 mt-2 mb-2 col-lg-6 shadow float-right">
    <h4><b>Full Name:</b>&nbsp;<i>{{Name}}</i></h4>
    <h4><b>Email Id:</b><i>&nbsp;{{Email}}</i></h4>
</div> 

<div>
    <app-widget-area [data]="bigChart"></app-widget-area>
</div>

<br>

<mat-divider></mat-divider>

<br>

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-cards">
    <mat-card fxFlex="25">
        <app-widget-card label="New Users" total="12k" percentage="50" [data]="cards"></app-widget-card>
    </mat-card>
    <mat-card fxFlex="25">
        <app-widget-card label="Users retention" total="10k" percentage="40" [data]="cards"></app-widget-card>
    </mat-card>
    <mat-card fxFlex="25">
        <app-widget-card label="Users engagement" total="8k" percentage="30" [data]="cards"></app-widget-card>
    </mat-card>
    <mat-card fxFlex="25">
        <app-widget-card label="Referral" total="6k" percentage="20" [data]="cards"></app-widget-card>
    </mat-card>
</div>

<br>

<mat-divider></mat-divider>

<br>

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="60">
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="weight">
                    <th mat-header-cell *matHeaderCellDef> Weight </th>
                    <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef> Symbol </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>

    </div>
    <div fxFlex="40">
        <app-widget-pie [data]="pieChart"></app-widget-pie>
    </div>
</div>

<br>
<br>
<br>
