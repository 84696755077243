import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ManageRoomsComponent } from './modules/manage-rooms/manage-rooms.component';
import { BookedComponent } from './modules/booked/booked.component';
// import { NgxCarouselModule } from 'ngx-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
 import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ToastrModule } from 'ngx-toastr';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ModalModule } from 'ngx-bootstrap/modal';


import { OwlDateTimeModule, OwlNativeDateTimeModule,
  DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

import 'hammerjs';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RoomsComponent } from './rooms/rooms.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AdminFeedbackComponent } from './app/modules/admin-feedback/admin-feedback.component';
import { NewDesignComponent } from './new-design/new-design.component';

import { SearchingComponent } from './searching/searching.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ImgInfoComponent } from './img-info/img-info.component';
import { GrdFilterPipe } from './grd-filter.pipe';
import { ReportsComponent } from './modules/reports/reports.component';
// import { GrdFilterPipe } from './grd-filter.pipe';
imports: [BrowserModule, NgxPaginationModule];


export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    ManageRoomsComponent,
    BookedComponent,
    ChangepasswordComponent,
    RoomsComponent,
    FeedbackComponent,
    AdminFeedbackComponent,
    NewDesignComponent,
    
    SearchingComponent,
    
    ChatbotComponent,
    
    ImgInfoComponent,
    
    GrdFilterPipe,
    
    ReportsComponent,
  
  
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    HttpClientModule,
    NgxSpinnerModule,
     BsDatepickerModule.forRoot(),
     OwlDateTimeModule, 
    OwlNativeDateTimeModule,

    NgxPaginationModule,
    Ng2SearchPipeModule,
    ModalModule.forRoot(),
    
    // NgxCarouselModule
    ToastrModule.forRoot() // ToastrModule added
    // ToastrModule.forRoot({
    //   timeOut: 1000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    // })
  ],
  providers: [{
    provide: LocationStrategy, 
    useClass: HashLocationStrategy
  },
  // { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
