import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteService } from '../services/site.service';
import { ManageRoomsService } from '../services/managerooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { DataService } from "../services/data.service";
import { Router } from '@angular/router';
import {Ng2TelInputModule} from 'ng2-tel-input';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {

  public booking: FormGroup;
  hideMediumDown: boolean = false;
  availInput;
  display='none';
  roomPrice; number = 0;
  sigdisplay = 'none';
  roomType:any[];
  branchList:any[];
  minDate: Date;
  checkoutMinDate: Date;
  roomNo : any;
  checkOutDateInput : any;
  roomNoInput : any;
  checkindate: any;
  calculated: any;
  public data;
  public groupArr:any;
  public data1 : any = [
    {
      floor:'1',
      room_type : 'Single Bed Room',
      room_no : '11',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Single Bed Room',
      room_no : '12',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Double Bed Room',
      room_no : '13',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'1',
      room_type : 'Double Bed Room',
      room_no : '14',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Single Bed Room',
      room_no : '21',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Single Bed Room',
      room_no : '22',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Double Bed Room',
      room_no : '23',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'2',
      room_type : 'Double Bed Room',
      room_no : '24',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Single Bed Room',
      room_no : '31',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Single Bed Room',
      room_no : '32',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Double Bed Room',
      room_no : '33',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'3',
      room_type : 'Double Bed Room',
      room_no : '34',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Single Bed Room',
      room_no : '41',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Single Bed Room',
      room_no : '42',
      product_price : '2000',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Double Bed Room',
      room_no : '43',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    {
      floor:'4',
      room_type : 'Double Bed Room',
      room_no : '44',
      product_price : '2500',
      status:'Available',
      product_image : 'assets/img/images.jpg'
    },
    

  ]
  route: any;

  get f() { return this.booking.controls; }

  constructor(private router:Router, private _fb: FormBuilder, private siteService: SiteService, private dataService: DataService,
    private roomService:ManageRoomsService, private spinner:NgxSpinnerService) { 
      this.minDate = new Date();
      
    }

    
  ngOnInit() {
  //   this.roomNo= this.route.snapshot.queryParamMap.get("room_no");
  //   this.route.queryParamMap.subscribe(queryParams => {
  //     this.roomNo = queryParams.get("room_no");

  //  })

    console.log(this.minDate);
    // this.roomNo = this.route.snapshot.paramMap.get("room_no")
    
    this.booking = this._fb.group({
      name: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      gender: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required]],
      address: ['', [Validators.required]],
      checkin: ['', [Validators.required]],
      checkout: ['', [Validators.required]],
      adults: ['', [Validators.required]],
      childrens: ['', [Validators.required]],
      rooms: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      room_type: ['', [Validators.required]],
      room_no: ['', [Validators.required]],
      rent:['',[Validators.required]]
      
  });
  
  // this.getRoomsList();
  this.getBranchList();
  // console.log(this.booking.controls['checkin'].value);

  this.dataService.getAvailData.subscribe(avilInput => this.availInput = avilInput);
  this.checkAvailability();
  
//   console.log(this.data1);
//   this.groupArr = this.data1.reduce((r,{floor})=>{
//    if(!r.some(o=>o.floor==floor)){
//      r.push({floor,groupItem:this.data1.filter(v=>v.floor==floor)});
//    }
//    return r;
//    },[]);
// console.log(this.groupArr);

}

bookRoom(e){
  console.log(e);
}


onValueChange(value: Date){
  console.log(value);
  this.checkoutMinDate = value;
}

onChangeRoomType(event){
     console.log(event);
    //  console.log(this.roomType);
    //  let selectedType = this.roomType.filter(item => {
    //      return item.room_type == event;
    //  })
    //  console.log(selectedType);
    //  this.booking.controls['adults'].patchValue(selectedType[0].adult);
    //  this.booking.controls['childrens'].patchValue(selectedType[0].children);
     
}

showModal(item) {

  this.sigdisplay = 'block';
  
  console.log(item);
  console.log(item.amount);
  this.booking.controls['room_type'].patchValue(item.room_type);
  this.booking.controls['branch'].patchValue(item.branch);
  this.booking.controls['rent'].patchValue(item.amount);
  this.booking.controls['room_no'].patchValue(item.room_no);
  
  
  this.roomNo = item.room_no;
  console.log(this.roomNo);
  let roomTypeName = '';
  roomTypeName=item.room_type;
  console.log(roomTypeName);
  this.roomType = [];
  this.roomService.getRoomType(roomTypeName).subscribe( response => {
    this.roomType = response['data'];

    this.booking.controls['adults'].patchValue(this.roomType[0].adult);
     this.booking.controls['childrens'].patchValue(this.roomType[0].children);
    
  })
     
     
  // this.onChangeRoomType(item.room_type);
 
  // console.log(this.availInput);
  // console.log(this.availInput.room_type);
  // this.booking.controls['checkin'].patchValue(this.availInput.checkIn);

  

  
  // this.booking.reset();
}

hideModal(): void {
  this.sigdisplay = 'none';
}
// openModal(data){
//   console.log(data);
//   this.display='block';
// }

// onCloseHandled(){
//   this.display='none';
// }

// getRoomsList(){
//   this.roomType = [];
//   this.roomService.getRoomType().subscribe( response => {
//     this.roomType = response['data'];
//     console.log(this.roomType);
//   })
// }

getBranchList(){
  this.branchList = [];
  this.roomService.getBranches().subscribe( response => {
    this.branchList = response['data'];
    console.log(this.branchList);
  })
}
  addBooking(model: FormGroup){
          this.spinner.show();
            console.log(model.value);
            this.checkOutDateInput = model.value.checkout;
            this.roomNoInput = model.value.room_no;
            console.log( this.checkOutDateInput, this.roomNoInput);
            // debugger;
          this.siteService.addBooking(model.value).subscribe(res =>{
          console.log(res);
          // this.siteService.apiChangeStatus(this.checkOutDateInput, this.roomNoInput).subscribe(response => {console.log(response)})
          this.spinner.hide();
          if(res.status == true){
            this.paymentBid(res.booking_id, res.email_id);
            // swal.fire(
            //   'Kindly pay to confirm your boking !!!',
            //   'Payment opens in next page',
            //   'warning'
            // ).then(() => {
              
            
            //   // this.router.navigate(['/']);
            // });
            
          }
          
         
        }),
        error => {
          this.spinner.hide();
          swal.fire(
            'Failes!',
             error,
            'error'
          ).then(() => {
           
            this.router.navigate(['/']);
          });  
         };
      
        
  }

  paymentBid(bookingId, mailId){
    this.siteService.apiBidPayment(bookingId, mailId).subscribe( response => {
        console.log(response);
        if(response.responseData.message) {
           swal.fire(
              'Kindly provide correct email',
              'Invalid email address',
              'warning'
            ).then(() => {
               window.location.href = "/"
            
                
              });
           
        }
        // this.spinner.hide();
      else {
        window.location.href = response.responseData.payment_request.longurl, '_blank';}

    });
  }


  checkAvailability(){
    this.data = [];
    this.checkindate = "";
    console.log(this.availInput);

    // this.spinner.show();
    this.siteService.checkAvailability(this.availInput).subscribe( response => {
      console.log(response);
      // this.spinner.hide();
      this.data = response.data;
      this.checkindate = response.checkindate;
     this.booking.controls['checkin'].patchValue(this.checkindate);
      // console.log(this.checkindate);
      // this.data.push({product_image : 'assets/img/images.jpg'});
      console.log(this.data);
      
      this.groupArr = this.data.reduce((r,{floor_no})=>{
       if(!r.some(o=>o.floor_no==floor_no)){
         r.push({floor_no,groupItem:this.data.filter(v=>v.floor_no==floor_no)});
       }
       return r;
       },[]);
    console.log(this.groupArr);
      // for(let j = 0; j < this.data.length; j++){
      //   this.data[j]['product_image'] = "assets/img/images.jpg";
      // }
      console.log(this.data);
      if(this.data === undefined || this.data.length == 0){
        // this.spinner.hide();
        swal.fire(
          'Failes!',
          'Rooms Not Available',
          'error'
        ).then(() => {
          this.router.navigate(['/']);
        }); 
    }
  },
  error => {
    console.log(error);
  },
  );
  
  
  }
  onKeyUpChange(event){
    console.log(event,"No of Rooms");
   this.calculated = this.booking.controls['rent'].value * event;
    
    // this.booking.controls['totalAmount'].patchValue(calculated)
    
  }

}
